<p-table [columns]="columnsConfig"
         [value]="packages"
         dataKey="packageId"
         [scrollable]="true"
         [scrollHeight]="'flex'"
         [style]="{'max-width': '1280px'}">
  <ng-template pTemplate="header"
               let-columns>
    <tr>
      <th scope="col"
          class="!flex-none w-16"></th>
      <th scope="col"
          *ngFor="let col of columnsConfig;let first = first; let last = last"
          class="text-primary-400 font-normal text-xs {{col.headerClass}}"
          [class.pl-0]="first"
          [class.pr-8]="last">
        {{col.header}}
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body"
               let-package
               let-expanded="expanded"
               let-columns="columns">
    <tr>
      <td class="!flex-none w-16">
        <a href="#"
           [pRowToggler]="package"
           *ngIf="package.packages?.length">
          <i class="text-primary-200"
             [ngClass]="expanded ? 'pi pi-fw pi-chevron-down' : 'pi pi-fw pi-chevron-right'"></i>
        </a>
      </td>
      <ng-container *ngTemplateOutlet="rowCells; context: {columns: columns, package: package, expansion: false }"></ng-container>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <td colspan="2">
      <div class="mt-2 ml-16">No items</div>
    </td>
  </ng-template>
  <ng-template pTemplate="loadingbody">
    <prt-table-loading-body #loading
                            [columns]=columnsConfig.length></prt-table-loading-body>
  </ng-template>
  <ng-template pTemplate="rowexpansion"
               let-package
               let-columns="columns">
    <tr *ngFor="let p of package.packages; let first = first;let last = last"
        class="expanded"
        [class.first]="first"
        [class.last]="last">
      <td></td>
      <ng-container *ngTemplateOutlet="rowCells; context: {columns: columns, package: p, expansion: true }"></ng-container>
    </tr>
  </ng-template>
</p-table>
<ng-template #rowCells
             let-columns="columns"
             let-package="package"
             let-expansion="expansion">
  <td *ngFor="let col of columns;let first = first; let last = last"
      [class.pl-0]="first"
      [class.pr-8]="last"
      class="{{col.headerClass}}">
    <ng-container [ngSwitch]="col.field">

      <span *ngSwitchCase="'packageTypeName'"
            [class.pl-2]="expansion">
        {{package.packageTypeName}}
      </span>

      <span *ngSwitchCase="'grossVolume'">
        <ng-container *ngIf="package.grossVolume">{{package.grossVolume}} {{package.volumeUom$ | async }}</ng-container>
      </span>

      <span *ngSwitchCase="'grossWeight'">
        <ng-container *ngIf="package.grossWeight">{{package.grossWeight }} {{package.weightUom$ | async }}</ng-container>
      </span>

      <div *ngSwitchCase="'dimensions'"
           class="flex items-center flex-wrap">
        <div *ngIf="package.length"><span class="text-2xs font-bold text-primary-300 mr-px">L</span><span class="ml-px mr-4">{{package.length }} {{package.dimensionUom$ | async }}</span></div>
        <div *ngIf="package.width"><span class="text-2xs font-bold text-primary-300 mr-px">W</span><span class="ml-px mr-4">{{package.width }} {{package.dimensionUom$ | async }}</span></div>
        <div *ngIf="package.length"><span class="text-2xs font-bold text-primary-300 mr-px">H</span><span class="ml-px">{{package.height }} {{package.dimensionUom$ | async }}</span></div>
      </div>

      <ng-container *ngSwitchCase="'photo'">
        <div class="flex items-center"
             *ngIf="package.documents?.length">
          <div class="table-thumbnail h-8 w-8 p-1 bg-primary-200 border border-transparent cursor-pointer hover:bg-primary-100 hover:border-primary-200 mr-2 flex items-center justify-center"
               [pTooltip]="'Click here to see package photos'"
               tooltipPosition="top"
               tooltipStyleClass="-mt-2"
               (keydown.enter)="openGallery(package)"
               (click)="openGallery(package)">
            <i class="pi pi-camera text-primary-500"></i>
          </div>
          ({{package.documents.length}})
        </div>
      </ng-container>

      <ng-container *ngSwitchDefault>
        <prt-table-cell [model]=package
                        [columnConfig]=col></prt-table-cell>
      </ng-container>
    </ng-container>
  </td>
</ng-template>