import { animate, style, transition, trigger } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HelperService } from 'src/app/core/services/helper.service';
import { ISidebarComponent } from 'src/app/layout/sidebar/sidebar-component.interface';
import { DeliveryInstruction } from 'src/app/shared/model/delivery-instruction/delivery-instruction.dto';
import { SharedModule } from 'src/app/shared/shared.module';
import { DeliveryRequestCartFormComponent } from './delivery-request-cart-form/delivery-request-cart-form.component';
import { DeliveryRequestCartService } from './delivery-request-cart.service';

@Component({
  standalone: true,
  templateUrl: './delivery-request-cart.component.html',
  styleUrls: ['./delivery-request-cart.component.scss'],
  imports: [SharedModule, DeliveryRequestCartFormComponent],
  animations: [
    trigger('appearAnimation', [
      transition(':enter', [
        style({ opacity: 0 }), // Start state
        animate('500ms ease-out', style({ opacity: 1 })) // End state
      ])
    ])
  ]
})
export class DeliveryRequestCartComponent implements OnInit, ISidebarComponent {
  size = 'xl';
  private destroying$ = new Subject<void>();
  cart: DeliveryInstruction[] = [];
  trackById(index: number, obj: any): number {
    return obj.id;
  }

  constructor(
    public deliveryRequestCartService: DeliveryRequestCartService,
    public helperService: HelperService
  ) { }

  ngOnInit(): void {
    this.deliveryRequestCartService.cart$.pipe(
      takeUntil(this.destroying$)
    ).subscribe(x => {
      this.cart = x;
    });
  }

  closeSuccessMessage(deliveryInstruction: DeliveryInstruction) {
    this.deliveryRequestCartService.hideDeliveryRequestSuccess(deliveryInstruction.id || '');
  }

  ngOnDestroy(): void {
    this.destroying$.next();
    this.destroying$.complete();
  }
}
