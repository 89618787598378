import { PurchaseOrderPackagesAndLinesDto } from './../../purchase-orders/purchase-order-lines.dto';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IOrder } from 'src/app/shared/model/order/order.interface';
const orderServiceUrl = environment.orderServiceApiUrl;

@Injectable({
  providedIn: 'root'
})
export class CargoDataService {

  constructor(private httpClient: HttpClient) { }

  getSalesOrder(groupCode: string, isGroupCustomer: boolean, poNumber: string): Observable<IOrder> {
    const headers = new HttpHeaders({
      'Prt-Is-Group-Customer': `${isGroupCustomer}`
    });

    const params = new HttpParams()
      .set('groupCustomerCode', groupCode)
      .set('poNumber', poNumber);

    return this.httpClient.get<IOrder>(`${orderServiceUrl}/cargo/getpodetails`, { headers: headers, params: params });
  }

  getPoLines(groupCode: string, isGroupCustomer: boolean, poNumber: string): Observable<PurchaseOrderPackagesAndLinesDto> {
    const headers = new HttpHeaders({
      'Prt-Is-Group-Customer': `${isGroupCustomer}`
    });

    const params = new HttpParams()
      .set('groupCustomerCode', groupCode)
      .set('poNumber', poNumber);

    return this.httpClient.get<PurchaseOrderPackagesAndLinesDto>(`${orderServiceUrl}/cargo/getpolines`, { headers: headers, params: params });
  }
}
