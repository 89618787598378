import { Component, Input, OnInit, Output, EventEmitter, ViewEncapsulation } from '@angular/core';
import { TableColumnsConfig } from 'src/app/shared/model/table-columns-config.model';
import { TransportMode } from 'src/app/shared/model/transport-mode.const';
import { ICargoLine } from 'src/app/shared/model/order/order.interface';
import { PurchaseOrderLineItemsColumns } from './purchase-order-line-items-columns.const';
import { TableColumnDisplayType } from 'src/app/shared/model/table-column-display-type.enum';
import { DateHelperService } from 'src/app/shared/services/date-helper.service';

@Component({
  selector: 'prt-purchase-order-line-items',
  templateUrl: './purchase-order-line-items.component.html',
  styleUrls: ['./purchase-order-line-items.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PurchaseOrderLineItemsComponent implements OnInit {
  _data?: ICargoLine[];
  @Input()
  set data(lineItems: ICargoLine[]) {
    lineItems.forEach(li => {
      if (li.operationalProcess?.configuration) {
        li.operationalProcess.configuration.progress = this.getProgress(li);
      }
    });
    this._data = lineItems.sort((a, b) => a.lineNumber - b.lineNumber);
  };
  @Input() showShipment: boolean = false;
  @Input() showCrates: boolean = false;
  @Input() showPhotos: boolean = false;
  @Input() heading?: string;
  @Input() headingIconUrl?: string;
  @Input() selectable: boolean = false;
  @Input() showHeading: boolean = true;
  @Output() lineItemSelected = new EventEmitter<ICargoLine>();
  transportMode = TransportMode;

  Math = Math;

  columnsConfig: TableColumnsConfig[] = [
    // { field: 'plant', header: 'Plant', headerClass: 'w-24' },
    { field: PurchaseOrderLineItemsColumns.partNumber, header: 'Part', headerClass: 'w-32' },
    { field: PurchaseOrderLineItemsColumns.externalPartNumber, header: 'Line Number' },
    { field: PurchaseOrderLineItemsColumns.cargoSupplierName, header: 'Supplier' },
    { field: PurchaseOrderLineItemsColumns.uomCode, header: 'UOM' },
    { field: PurchaseOrderLineItemsColumns.expectedQuantity, header: 'Planned Qty' },
    { field: PurchaseOrderLineItemsColumns.receivedQuantity, header: 'Actual Qty' },
    { field: PurchaseOrderLineItemsColumns.lineStatus, header: 'Line Status', headerClass: 'w-20' },
    {
      field: PurchaseOrderLineItemsColumns.requiredDeliveryDate, header: 'Expected Ready', type: TableColumnDisplayType.twoRows, headerClass: 'w-24',
      firstRow: { field: PurchaseOrderLineItemsColumns.requiredDeliveryDate, type: TableColumnDisplayType.mediumDate, class: 'whitespace-nowrap' },
      secondRow: { field: PurchaseOrderLineItemsColumns.requiredDeliveryDate, type: TableColumnDisplayType.shortTime }
    },
    {
      field: PurchaseOrderLineItemsColumns.readinessDate, header: 'Ready for Collection', type: TableColumnDisplayType.twoRows, headerClass: 'w-24',
      firstRow: { field: PurchaseOrderLineItemsColumns.readinessDate, type: TableColumnDisplayType.mediumDate, class: 'whitespace-nowrap' },
      secondRow: { field: PurchaseOrderLineItemsColumns.readinessDate, type: TableColumnDisplayType.shortTime }
    },
    {
      field: PurchaseOrderLineItemsColumns.expiryDate, header: 'Delivery Scheduled For', type: TableColumnDisplayType.twoRows, headerClass: 'w-24',
      firstRow: { field: PurchaseOrderLineItemsColumns.expiryDate, type: TableColumnDisplayType.mediumDate, class: 'whitespace-nowrap' },
      secondRow: { field: PurchaseOrderLineItemsColumns.expiryDate, type: TableColumnDisplayType.shortTime }
    },
    { field: PurchaseOrderLineItemsColumns.isHazardous, header: 'Hazardous', headerClass: 'w-20' },
  ];
  constructor() {
  }

  ngOnInit(): void {
    if (this.showShipment) {
      this.columnsConfig.push(
        { field: 'shipment', header: 'Shipment', headerClass: 'w-32' }
      );
    }
    if (this.showCrates) {
      this.columnsConfig.push(
        { field: 'crates', header: 'MR', headerClass: 'w-32' }
      );
    }
    if (this.showPhotos) {
      this.columnsConfig.push(
        { field: 'photos', header: 'MR', headerClass: 'w-32' }
      );
    }
  }

  onRowClick(lineItem: ICargoLine): void {
    if (!this.selectable) {
      return;
    }

    this.lineItemSelected.emit(lineItem);
  }

  getProgress(lineItem: ICargoLine): number {
    let conf = lineItem.operationalProcess?.configuration;
    if (conf) {
      return DateHelperService.getEtaProgress(conf.eta, conf.etd) ?? 0;
    }
    return 0;
  }

}
