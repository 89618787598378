import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  CartDto,
  DeliveryInstruction,
  DeliveryInstructionDto,
} from '../shared/model/delivery-instruction/delivery-instruction.dto';
import { DeliveryInstructionsQueryDto } from '../shared/model/delivery-instruction/delivery-instructions-query.dto';
import { SendDeliveryInstructionResponse } from '../shared/model/delivery-instruction/send-delivery-instruction-response.dto';
import { ValidatedCartResponse } from '../shared/model/delivery-instruction/validated-cart-response.dto';
import { PageResponseDto } from '../shared/model/page-response.dto';
import { PackageCodePoNumber } from './../shared/model/delivery-instruction/package-code-po-number.dto';
const portalApiUrl = environment.portalApiUrl;
const serviceUrl = 'deliveryinstruction';

@Injectable({
  providedIn: 'root',
})
export class DeliveryRequestDataService {
  apiUrl = `${portalApiUrl}/${serviceUrl}`;
  constructor(private readonly http: HttpClient) { }

  sendInstruction(
    deliveryInstructionDto: DeliveryInstructionDto
  ): Observable<SendDeliveryInstructionResponse> {
    return this.http.post<SendDeliveryInstructionResponse>(
      `${this.apiUrl}`,
      deliveryInstructionDto
    );
  }

  addToCart(
    cartDto: CartDto
  ): Observable<ValidatedCartResponse<DeliveryInstruction | null>> {
    return this.http.post<ValidatedCartResponse<DeliveryInstruction | null>>(
      `${this.apiUrl}/addtocart`,
      cartDto
    );
  }

  removePackagesFromCart(
    id: string,
    packageCodePoNumberPairs: PackageCodePoNumber[]
  ): Observable<DeliveryInstruction | null> {
    return this.http.post<DeliveryInstruction>(
      `${this.apiUrl}/removepackages`,
      {
        id,
        packageCodePoNumberPairs,
      }
    );
  }

  cancelDeliveryInstruction(
    requestNumber: string
  ): Observable<DeliveryInstruction> {
    return this.http.patch<DeliveryInstruction>(`${this.apiUrl}/cancel`, null, {
      params: {
        requestNumber,
      },
    });
  }

  getCart(): Observable<ValidatedCartResponse<DeliveryInstruction[]>> {
    return this.http.get<ValidatedCartResponse<DeliveryInstruction[]>>(
      `${this.apiUrl}`
    );
  }

  getAll(
    query: DeliveryInstructionsQueryDto
  ): Observable<PageResponseDto<DeliveryInstruction>> {
    return this.http.post<PageResponseDto<DeliveryInstruction>>(
      `${this.apiUrl}/getall`,
      query
    );
  }

  getDeliveryInstructionsForPackages(
    packageCodes: string[]
  ): Observable<DeliveryInstruction[]> {
    return this.http.post<DeliveryInstruction[]>(
      `${this.apiUrl}/getdeliveryinstructionsforpackages`,
      packageCodes
    );
  }
}
