import { IMrnGroup } from './../warehouse-table.component';
import { ChangeDetectionStrategy, Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { TableColumnsConfig } from 'src/app/shared/model/table-columns-config.model';

@Component({
  selector: 'prt-warehouse-table-packages',
  templateUrl: './warehouse-table-packages.component.html',
  styleUrls: ['./warehouse-table-packages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class WarehouseTablePackagesComponent implements OnInit {
  expandedColumnsConfig: TableColumnsConfig[] = [
    { field: 'packageCode', header: 'Package/GAC SCAN ID', class: 'whitespace-nowrap' },
    { field: 'noOfPackage', header: 'NO. OF PACKAGES' },
    { field: 'receivedQuantity', header: 'RECVD. QTY' },
    { field: 'receivedAt', header: 'RECEIVED', },
    { field: 'pickedAt', header: 'PICKED', },
    { field: 'packedAt', header: 'PACKED', },
    { field: 'bookedAt', header: 'BOOKING', },
    { field: 'dispatchedAt', header: 'DISPATCHED', },
  ];
  Math = Math;

  @Input() mrnGroup?: IMrnGroup;


  constructor() { }

  ngOnInit(): void {
  }

}
