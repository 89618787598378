import { ShipmentTrail } from './../../model/shipment-trail.model';
import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ModeOfTransport } from 'src/app/shared/model/transport-mode.const';
import { Constants } from 'src/app/core/services/constants.service';

@Component({
  selector: 'prt-shipment-trail-mini',
  templateUrl: './shipment-trail-mini.component.html',
  styleUrls: ['./shipment-trail-mini.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShipmentTrailMiniComponent implements OnInit {
  @Input() shipmentTrail!: ShipmentTrail;
  @Input() loading: boolean = false;
  Constants = Constants;
  modeOfTransport = ModeOfTransport;

  constructor(private router: Router) {
  }

  ngOnInit(): void {
  }

  openNewTab(event: UIEvent): void {
    event.preventDefault();
    const url = this.router.serializeUrl(
      this.router.createUrlTree([`/shipments/${this.shipmentTrail?.opId}/${this.shipmentTrail?.jobNumber}`])
    );

    window.open(url, '_blank');
  }
}
