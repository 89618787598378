import { Component, Input, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { TableCellTemplatesComponent } from 'src/app/shared/components/table-cell-templates/table-cell-templates.component';
import { TableColumnDisplayType } from 'src/app/shared/model/table-column-display-type.enum';
import { TableColumnsConfig } from 'src/app/shared/model/table-columns-config.model';
import { CargoGroup } from '../../../shipments/shipment/shipment.service';

@Component({
  selector: 'prt-shipment-purchase-orders-grid',
  templateUrl: './shipment-purchase-orders-grid.component.html',
  styleUrls: ['./shipment-purchase-orders-grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShipmentPurchaseOrdersGridComponent implements OnInit {
  @ViewChild(TableCellTemplatesComponent) public tableCellTemplates?: TableCellTemplatesComponent;

  @ViewChild('rigCodeTemplate') rigCodeTemplate?: TemplateRef<any>;
  @ViewChild('vesselNameTemplate') vesselNameTemplate?: TemplateRef<any>;

  columnsConfig: TableColumnsConfig[] = [];

  @Input() isLoading: boolean = true;
  _cargoGroups: CargoGroup[] = [];
  @Input()
  set cargoGroups(cgs: CargoGroup[]) {
    this._cargoGroups = cgs;
  };
  constructor(
    private router: Router) { }

  ngOnInit(): void {
    setTimeout(() => {
      this.columnsConfig = [
        { field: 'poNumber', header: 'PO Number', class: 'font-medium text-sm-plus' },
        {
          field: 'rigCode', header: 'VESSEL/RIG', type: TableColumnDisplayType.twoRows, class: 'font-medium text-sm-plus',
          firstRow: { field: 'rigCode', type: TableColumnDisplayType.template, template: this.rigCodeTemplate },
          secondRow: { field: 'rigName', type: TableColumnDisplayType.template, template: this.vesselNameTemplate }
        },
        { field: 'supplier', header: 'Supplier' },
        // { field: 'lineItemQuantity', header: 'Line Item quantity' },
        { field: 'poDate', header: 'PO Date', type: TableColumnDisplayType.dateTime },
        { field: 'requestedDate', header: 'Required Date', type: TableColumnDisplayType.dateTime },
        { field: 'status', header: 'Status', type: TableColumnDisplayType.chip },
      ];
    });
  }

  openNewTab(event: UIEvent, cargoId: string | number, poNumber: string): void {
    // prevent expander
    event.stopPropagation();
    event.preventDefault();
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['purchase-orders', cargoId, poNumber])
    );

    window.open(url, '_blank');
  }

}
