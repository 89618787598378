<p-table [columns]="expandedColumnsConfig"
         [value]="mrnGroup?.warehouseItems || []"
         class="gray-header-table da-table"
         [autoLayout]=true
         [dataKey]="'guid'">
  <ng-template pTemplate="header"
               let-col>
    <tr>
      <th scope="col"
          *ngFor="let col of col"
          class="{{col.headerClass}}">
        {{col.header}}
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body"
               let-warehouseItem
               let-columns="columns"
               let-expanded="expanded">
    <tr class="table-row"
        [class.active]="warehouseItem.isSelected">
      <td *ngFor="let col of columns">
        <ng-container [ngSwitch]="col.field">
          <div *ngSwitchCase="'isBooked'">
            <prt-table-cell *ngIf="warehouseItem.isBooked !== null"
                            [model]=warehouseItem
                            [columnConfig]=col></prt-table-cell>
            <span *ngIf="warehouseItem.isBooked === null">N/A</span>
          </div>

          <div *ngSwitchCase="'isDispatched'">
            <prt-table-cell *ngIf="warehouseItem.isDispatched !== null"
                            [model]=warehouseItem
                            [columnConfig]=col></prt-table-cell>
            <span *ngIf="warehouseItem.isDispatched === null">N/A</span>
          </div>
          <div *ngSwitchCase="'photos'"
               class="flex">
            <ng-container *ngIf="warehouseItem.photos?.length > 0">
              <img *ngFor="let x of [].constructor(Math.min(warehouseItem.photos.length, 3));  let i = index"
                   src="{{warehouseItem.photos[i].thumbnailUrl}}"
                   alt="warehouse item thumbnail"
                   class="table-thumbnail"
                   style="margin-right:-6px; z-index:{{4-i}}" />
              <ng-container *ngIf="warehouseItem.photos.length > 3">
                <div class="table-thumbnail ml-1 bg-primary-200">+{{warehouseItem.photos.length-3}}</div>
              </ng-container>
            </ng-container>
          </div>
          <ng-container *ngSwitchDefault>
            <prt-table-cell [model]=warehouseItem
                            [columnConfig]=col></prt-table-cell>
          </ng-container>
        </ng-container>
      </td>
    </tr>
  </ng-template>
</p-table>