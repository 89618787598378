<prt-table-cell-templates></prt-table-cell-templates>

<div class="overflow-x-auto shipment-po-grid">
  <p-table [columns]="columnsConfig"
           [value]="_cargoGroups"
           dataKey="shipmentPo.id"
           [autoLayout]=true
           [loading]="isLoading"
           [showLoader]="false"
           class="hide-first-skeleton-column shipment-po-list-table">
    <!-- PO LIST header -->
    <ng-template pTemplate="header"
                 let-columns>
      <tr>
        <th></th>
        <th *ngFor="let col of columnsConfig; let i = index"
            class="text-primary-400 text-xs"
            [attr.colspan]="i==0 ? 2:1">
          {{col.header}}
        </th>
      </tr>
    </ng-template>
    <!-- PO LIST body  -->
    <ng-template pTemplate="body"
                 let-cargoGroup
                 let-expanded="expanded"
                 let-columns="columns">
      <tr>
        <td></td>
        <td style="width: 2rem;padding-right:0;padding-left:0;">
          <a href="#"
             [pRowToggler]="cargoGroup">
            <i class="text-primary-200"
               [ngClass]="expanded ? 'pi pi-fw pi-chevron-down' : 'pi pi-fw pi-chevron-right'"></i>
          </a>
        </td>
        <td *ngFor="let col of columns"
            [class]=col.class>
          <ng-container [ngSwitch]="col.field">
            <span *ngSwitchCase="'poNumber'">{{cargoGroup?.shipmentPo?.poNumber}} <a href="#"
                 *ngIf="cargoGroup?.shipmentPo?.groupCustomerCode && cargoGroup?.shipmentPo?.poNumber"
                 [pTooltip]="'Click to open Purchase Order details in new tab'"
                 tooltipPosition="top"
                 tooltipStyleClass="-mt-2"
                 (click)="openNewTab($event, cargoGroup?.shipmentPo?.groupCustomerCode, cargoGroup?.shipmentPo?.poNumber)"
                 class="ml-4"><i class="pi pi-external-link text-xs text-blue-400"></i></a></span>
            <span *ngSwitchCase="'rigCode'">
              <ng-container *ngTemplateOutlet="$any(tableCellTemplates)[col.type || 'primitive']; context: {model: cargoGroup, col: col, vm: tableCellTemplates}"></ng-container>

            </span>
            <ng-container *ngSwitchDefault>
              <ng-container *ngTemplateOutlet="$any(tableCellTemplates)[col.type || 'primitive']; context: {model: cargoGroup.shipmentPo, col: col, vm: tableCellTemplates}"></ng-container>
            </ng-container>
          </ng-container>
        </td>
      </tr>
    </ng-template>
    <!-- LINE ITEMS expanded  -->
    <ng-template pTemplate="rowexpansion"
                 let-cargoGroup
                 let-columns="columns">
      <tr>
        <td class="bg-primary-50 pl-5 py-4-plus pr-5 sub-table"
            [attr.colspan]="columns.length + 2">
          <div class="card mt-0">
            <prt-purchase-order-packages-grid [data]="cargoGroup?.packagesAndLinesGrouped?.packages"
                                              *ngIf="cargoGroup?.packagesAndLinesGrouped?.packages && cargoGroup?.packagesAndLinesGrouped?.packages?.length"
                                              [showHeading]="false">
            </prt-purchase-order-packages-grid>
            <div *ngIf="!cargoGroup?.packagesAndLinesGrouped?.packages?.length">
              No items
            </div>
          </div>
        </td>
      </tr>
    </ng-template>
    <ng-template pTemplate="loadingbody">
      <prt-table-loading-body #loading
                              [columns]=7
                              *ngIf="columnsConfig"></prt-table-loading-body>
    </ng-template>
  </p-table>
</div>
<ng-template #rigCodeTemplate
             let-cargoGroup="model">
  <div>
    <span *ngIf="(cargoGroup?.shipmentPo?.isMultipleVessel || cargoGroup?.packagesAndLinesGrouped?.hasFleetStock) && !cargoGroup?.packagesAndLinesGrouped?.isFleetStock"
          [pTooltip]="'Multiple Vessel'"
          tooltipPosition="top"
          tooltipStyleClass="-mt-2">
      <img [src]="'/assets/images/cargoShips.svg'"
           class="w-5 h-5 mr-3 inline" />N/A</span>
    <span *ngIf="!!cargoGroup?.packagesAndLinesGrouped?.isFleetStock"
          [pTooltip]="'Fleet Stock'"
          tooltipPosition="top"
          tooltipStyleClass="-mt-2">
      <img [src]="'/assets/images/crateVerified.svg'"
           class="w-5 h-5 mr-3 inline" />N/A</span>
    <ng-container *ngIf="!(cargoGroup?.isMultipleVessel || cargoGroup?.packagesAndLinesGrouped?.hasFleetStock || cargoGroup?.packagesAndLinesGrouped?.isFleetStock)">{{cargoGroup?.shipmentPo?.rigCode || 'N/A'}}
    </ng-container>
  </div>
</ng-template>
<ng-template #vesselNameTemplate
             let-cargoGroup="model">
  <div class="font-normal text-sm"
       *ngIf="!(cargoGroup?.shipmentPo?.isMultipleVessel || cargoGroup?.packagesAndLinesGrouped?.hasFleetStock || cargoGroup?.packagesAndLinesGrouped?.isFleetStock)">
    {{cargoGroup?.shipmentPo?.rigName ||
    ''}}&nbsp;</div>
</ng-template>