import { NgModule } from '@angular/core';
import { TagModule } from 'primeng/tag';
import { SharedLogisticsModule } from '../shared-logistics/shared-logistics.module';
import { SharedModule } from '../shared/shared.module';
import { DeliveryRequestPackagesGridComponent } from './delivery-request-packages-grid/delivery-request-packages-grid.component';
import { DeliveryRequestComponent } from './delivery-request/delivery-request.component';
import { DeliveryRequestsRoutingModule } from './delivery-requests-routing.module';
import { DeliveryRequestsComponent } from './delivery-requests.component';

@NgModule({
  imports: [
    SharedModule,
    SharedLogisticsModule,
    DeliveryRequestsRoutingModule,
    TagModule,
  ],
  declarations: [
    DeliveryRequestsComponent,
    DeliveryRequestPackagesGridComponent,
    DeliveryRequestComponent,
  ],
  providers: [],
  exports: [DeliveryRequestPackagesGridComponent],
})
export class DeliveryRequestsModule { }
