import { LineItemStage, LineItemStageEnum } from '../shared-logistics/model/line-item-stage.enum';
import { ICargo, ICargoLine } from '../shared/model/order/order.interface';
import { IPackage } from "../shared/model/order/package.interface";
import { ShipmentPoDto } from '../shared/model/shipment/shipment-po.dto';
import { PurchaseOrderPackagesAndLinesDto } from './purchase-order-lines.dto';

const controlTowerPrefix = 'pkg';

export class PurchaseOrderPackagesAndLinesGrouped {
  constructor(
    public groups: PurchaseOrderPackagesAndLinesGroup[] = [],
    public packages: IPackage[] = [],
    public hasFleetStock = false,
    public isFleetStock = false
  ) {
  }

  public static BuildFromShipmentPo(shipmentPo: ShipmentPoDto) {
    shipmentPo.packages?.forEach(p => p.poNumber = shipmentPo.poNumber);
    let dto: PurchaseOrderPackagesAndLinesDto = {
      packages: shipmentPo.packages,
      cargoLines: []
    };
    return PurchaseOrderPackagesAndLinesGrouped.Build(dto);
  }

  public static BuildFromCargo(cargo: ICargo | undefined): PurchaseOrderPackagesAndLinesGrouped {
    cargo?.packages?.forEach(p => p.poNumber = cargo.poNumber);
    let dto: PurchaseOrderPackagesAndLinesDto = {
      packages: cargo?.packages || [],
      cargoLines: cargo?.cargoLines || []
    };
    return PurchaseOrderPackagesAndLinesGrouped.Build(dto);
  }

  public static Build(poLinesDto: PurchaseOrderPackagesAndLinesDto): PurchaseOrderPackagesAndLinesGrouped {
    const result = new PurchaseOrderPackagesAndLinesGrouped();

    let allCargoPackagedLineIds: number[] = [];

    if (poLinesDto?.packages) {
      let hasFleetStock = false;
      let allFleetStock = true;

      poLinesDto.packages
        .filter(x => x.isActive != false && x.packageState !== 'Cancelled')
        .filter(p => !p.isOverPack)
        .filter(p => !p.packageCode?.toLowerCase().startsWith(controlTowerPrefix.toLowerCase()))
        .forEach(p => {
          result.packages.push(p);
          if (p.isFleetStock) {
            hasFleetStock = true;
          }
          else {
            allFleetStock = false;
          }
          //first remove inactive cargo lineitems
          p.cargoLines = p.cargoLines?.filter(x => x.isActive != false && x.lineStatusName !== 'Cancelled');

          let overpack = poLinesDto.packages?.find(x => x.packageId === p.parentPackageId);
          if (overpack) {
            p.parentPackageCode = overpack.packageCode;
          }
          let stage: LineItemStage | null = null;

          stage = LineItemStageEnum[p.packageState];

          let orderLineGroup = result.groups.find(g => g.stage == stage);
          if (!orderLineGroup) {
            orderLineGroup = new PurchaseOrderPackagesAndLinesGroup(stage, [], []);
            result.groups.push(orderLineGroup);
          }
          orderLineGroup?.packages?.push(p);

          p.cargoLines?.filter(x => x.isActive != false).forEach(cl => {
            allCargoPackagedLineIds.push(cl.cargoLineId);
          });
        });
      result.hasFleetStock = hasFleetStock;
      result.isFleetStock = hasFleetStock && allFleetStock;
    }

    // adding all not packaged line items
    let newGroup = false;
    let anyAdded = false;

    let commonGroup = result.groups.find(g => g.stage == LineItemStageEnum.COMMON);
    if (!commonGroup) {
      commonGroup = new PurchaseOrderPackagesAndLinesGroup(LineItemStageEnum.COMMON, [], []);
      newGroup = true;
    }

    poLinesDto?.cargoLines?.filter(x => x.isActive != false && x.lineStatusName !== 'Cancelled').forEach(cli => {
      if (allCargoPackagedLineIds.indexOf(cli.cargoLineId) === -1) {
        commonGroup?.lineItems?.push(cli);
        anyAdded = true;
      }
    });

    if (newGroup && anyAdded) {
      result.groups.push(commonGroup);
    }

    return result;
  }
}

export class PurchaseOrderPackagesAndLinesGroup {
  constructor(
    public stage?: LineItemStage,
    public packages?: IPackage[],
    public lineItems?: ICargoLine[]
  ) { }
}