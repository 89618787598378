import { formatDate } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, ViewEncapsulation } from '@angular/core';
import { ConfirmationService, MessageService } from 'primeng/api';
import { Constants } from 'src/app/core/services/constants.service';
import { HelperService } from 'src/app/core/services/helper.service';
import { DeliveryInstructionStatusMetadata, DeliveryInstructionStatuses } from 'src/app/shared/model/delivery-instruction/delivery-instruction-status.enum';
import { DeliveryInstruction } from 'src/app/shared/model/delivery-instruction/delivery-instruction.dto';
import { DeliveryRequestDataService } from '../delivery-request-data.service';

@Component({
  selector: 'prt-delivery-request',
  templateUrl: './delivery-request.component.html',
  styleUrls: ['./delivery-request.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ConfirmationService],
  encapsulation: ViewEncapsulation.None,
})
export class DeliveryRequestComponent {
  chunkedDeliveryRequestInfo: any[][] = [];
  status?: DeliveryInstructionStatusMetadata;
  isCancelAllowed: boolean = true;
  _deliveryRequest: DeliveryInstruction | null = null;
  @Input()
  set deliveryRequest(x: DeliveryInstruction | null) {
    this._deliveryRequest = x;
    this.chunkedDeliveryRequestInfo = HelperService.chunkArray(this.parseDeliveryRequestInfo(x), 3);
    if (x?.status) {
      this.status = DeliveryInstructionStatuses[x.status];
    }
    this.isCancelAllowed = (this.status === DeliveryInstructionStatuses.Pending);
  }
  isCancelLoading: boolean = false;

  constructor(
    private deliveryRequestDataService: DeliveryRequestDataService,
    private confirmationService: ConfirmationService,
    private toastService: MessageService,
    private changeDetector: ChangeDetectorRef
  ) { }

  parseDeliveryRequestInfo(deliveryRequest: DeliveryInstruction | null): any[] {
    return [
      {
        label: 'Customer Name',
        data: deliveryRequest?.customerName,
      },
      {
        label: 'Delivery To',
        data: deliveryRequest?.vesselName || deliveryRequest?.rigCode || deliveryRequest?.vesselCode,
      },
      {
        label: 'Exp Delivery Date/Time',
        data: formatDate(
          deliveryRequest?.expectedDeliveryDate?.toString() ?? '',
          Constants.dateTimeFormat,
          'en-US'
        ),
      },
      {
        label: 'Pref Mode Of Transport',
        data: deliveryRequest?.modeOfTransport,
      },
      {
        label: 'Reference No',
        data: deliveryRequest?.customerReferenceNumber,
      },
      {
        label: 'Creation Date',
        data: formatDate(
          deliveryRequest?.createdOn?.toString() ?? '',
          Constants.dateTimeFormat,
          'en-US'
        ),
      },
    ];
  }

  clickCancel(id: string | undefined, event: Event) {
    event.stopPropagation();
    this.confirmationService.confirm({
      target: event.target as HTMLElement,
      message: 'Are you sure you want to cancel this request?',
      icon: 'pi pi-exclamation-triangle',
      accept: () => {
        this.cancelRequest(id);
      },
    });
  }

  cancelRequest(requestNumber: string = '') {
    this.isCancelLoading = true;
    this.deliveryRequestDataService
      .cancelDeliveryInstruction(requestNumber)
      .subscribe((resp) => {
        this._deliveryRequest!.status = DeliveryInstructionStatuses.Cancelled.type;
        this.isCancelLoading = false;
        this.toastService.add({
          severity: 'success',
          summary: 'Request cancelled successfully',
          styleClass: 'no-detail',
        });
        // setTimeout(() => {
        this.deliveryRequest = this._deliveryRequest;
        // });
        this.changeDetector.detectChanges();
      });
  }
}
