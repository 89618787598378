<div>
  <h2 *ngIf="heading"
      class="font-medium text-sm mb-5 text-primary-700 tracking-wide-display">{{heading}}</h2>
  <div class="card p-0">
    <p-table [columns]="columnsConfig"
             [value]="warehouseItems"
             class="gray-header-table da-table"
             [autoLayout]=true
             [loading]="!!isLoading"
             [showLoader]="false"
             [expandedRowKeys]="expandedRowKeys"
             [dataKey]="'mrNumber'">
      <ng-template pTemplate="header"
                   let-columns>
        <tr>
          <th scope="col"
              class="w-3"></th>
          <th scope="col"
              *ngFor="let col of columnsConfig"
              class="{{col.headerClass}}">
            {{col.header}}
          </th>
        </tr>
      </ng-template>
      <ng-template pTemplate="loadingbody">
        <prt-table-loading-body #loading
                                [columns]="columnsConfig.length"></prt-table-loading-body>
      </ng-template>
      <ng-template pTemplate="emptymessage">
        <td>
          <div class="ml-4 my-3">No items</div>
        </td>
      </ng-template>
      <ng-template pTemplate="body"
                   let-lineItem
                   let-columns="columns"
                   let-expanded="expanded">
        <tr class="table-row">
          <td>
            <button type="button"
                    pButton
                    pRipple
                    [pRowToggler]="lineItem"
                    class="p-button-text p-button-rounded p-button-plain"
                    [icon]="expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-right'"></button>
          </td>
          <td *ngFor="let col of columns">
            <ng-container [ngSwitch]="col.field">
              <div *ngSwitchCase="'mrNumber'"
                   class="flex ">
                <div class="relative flex items-center pr-6">
                  <prt-table-cell *ngIf="lineItem.grnExternalDocumentId"
                                  [model]=lineItem
                                  [columnConfig]=col
                                  (keydown.enter)="downloadGrnDocument(lineItem)"
                                  (click)="downloadGrnDocument(lineItem)"
                                  class="underline cursor-pointer"></prt-table-cell>
                  <prt-table-cell *ngIf="!lineItem.grnExternalDocumentId && (lineItem.mrNumber !== null && lineItem.mrNumber!== 'null')"
                                  [model]=lineItem
                                  [columnConfig]=col
                                  (keydown.enter)="generateMRDocument(lineItem)"
                                  (click)="generateMRDocument(lineItem)"
                                  [ngClass]="lineItem.source == OrderSource.Legacy ? 'underline cursor-pointer': ''">
                  </prt-table-cell>
                  <span *ngIf="lineItem.mrNumber === null || lineItem.mrNumber === 'null'">N/A</span>
                  <i class="pi pi-spin pi-spinner mx-2 absolute right-0"
                     style="font-size: 12px"
                     *ngIf="lineItem.generatingDocument"></i>
                </div>
              </div>
              <div *ngSwitchCase="'isBooked'">
                <prt-table-cell *ngIf="lineItem.isBooked !== null"
                                [model]=lineItem
                                [columnConfig]=col></prt-table-cell>
                <span *ngIf="lineItem.isBooked === null">N/A</span>
              </div>
              <ng-container *ngSwitchDefault>
                <prt-table-cell [model]=lineItem
                                [columnConfig]=col></prt-table-cell>
              </ng-container>
            </ng-container>
          </td>
        </tr>
      </ng-template>
      <ng-template pTemplate="rowexpansion"
                   let-lineItem>
        <tr>
          <td colspan="3"
              class="bg-primary-50">
            <div class="card mt-0">
              <prt-warehouse-table-packages [mrnGroup]="lineItem"> </prt-warehouse-table-packages>
            </div>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>