type PurchaseOrderStatus = 'Arrived' | 'Cancelled' | 'Complete' | 'Delivered' | 'Exceed' | 'ExcessReceived' | 'Hanging' | 'InProgress' | 'InTransit' | 'InWarehouse' | 'Open' | 'Partial' | 'PartialReceived' | 'PartiallyArrived' | 'PartiallyDelivered' | 'PartiallyInTransit' | 'Pending' | 'Received';
export const PurchaseOrderStageEnum: Record<PurchaseOrderStatus, string> = {
  Arrived: 'Arrived',
  Cancelled: 'Cancelled',
  Complete: 'Complete',
  Delivered: 'Delivered',
  Exceed: 'Exceed',
  ExcessReceived: 'Excess Received',
  Hanging: 'Hanging',
  InProgress: 'In Progress',
  InTransit: 'In Transit',
  InWarehouse: 'In Warehouse',
  Open: 'Open',
  Partial: 'Partial',
  PartialReceived: 'Partial Received',
  PartiallyArrived: 'Partially Arrived',
  PartiallyDelivered: 'Partially Delivered',
  PartiallyInTransit: 'Partially In Transit',
  Pending: 'Pending',
  Received: 'Received'
};
