<p-table [value]="packages"
         class="table-border-bottom-none no-x-padding"
         [autoLayout]=true
         [showLoader]="false"
         [loading]="!_shipment"
         selectionMode="single">
  <ng-template pTemplate="header"
               let-columns>
    <tr class="text-primary-400 text-xs">
      <th scope="col"
          class="text-primary-400 ">Gross Weight </th>
      <th scope="col"
          class="text-primary-400 ">Gross Volume </th>
      <th scope="col"
          class="text-primary-400 ">No. of packages </th>
      <th scope="col"
          class="w-11"> </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body"
               let-lineItem
               let-columns="columns">
    <tr>
      <td>
        <ng-container *ngIf="lineItem.weight[1];else notAvailable">
          {{lineItem.weight[0] | number: '1.0-4'}} {{lineItem.weight[1] | async}}
        </ng-container>
      </td>
      <td>
        <ng-container *ngIf="lineItem.volume[1];else notAvailable">
          {{lineItem.volume[0] | number: '1.0-4'}} {{lineItem.volume[1] | async}}
        </ng-container>
      </td>
      <td>
        <div class="flex flex-col">
          <div class="flex"
               *ngFor="let p of lineItem.packageTypeList | keyvalue">
            {{p.value}} <span *ngIf="p.key"
                  class="mx-1 text-primary-400">x</span> {{p.key | singularize}}
          </div>
        </div>
      </td>
      <td class="pl-0 pr-2">
        <button pButton
                icon="pi pi-search"
                *ngIf="(portalCustomerConfigurationService.customerConfiguration$ | async)?.isPurchaseOrderVisible"
                [pTooltip]="'Click to view package details'"
                tooltipPosition="right"
                tooltipStyleClass="ml-2"
                class="p-button-secondary search-btn p-button-sm -mt-1"
                (click)="showPackageDetails($event)"></button>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <td [colSpan]="2">
      <div class="mt-2">No items</div>
    </td>

  </ng-template>
  <ng-template pTemplate="loadingbody">
    <prt-table-loading-body [rows]="1"
                            [columns]=4></prt-table-loading-body>
  </ng-template>
</p-table>
<ng-template #notAvailable>N/A</ng-template>