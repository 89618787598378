export const PurchaseOrderPackagesColumns = {
  packageNumber: 'packageNumber',
  packageCode: 'packageCode',
  noOfPackages: 'noOfPackages',
  packageTypeName: 'packageTypeName',
  dimensions: 'dimensions',
  packageLocation: 'packageLocation',
  vessel: 'rigCode',
  shipment: 'shipment',
  delivery: 'isSelectedForDelivery'
};