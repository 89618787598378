export type DeliveryInstructionStatusType =
  | 'Draft'
  | 'Pending'
  | 'InProgress'
  | 'Dispatched'
  | 'Cancelled';

export interface DeliveryInstructionStatusMetadata {
  type: DeliveryInstructionStatusType;
  order: number;
  label: string;
  severity: "success" | "secondary" | "info" | "warning" | "danger" | "contrast";
}

export const DeliveryInstructionStatuses: Record<
  DeliveryInstructionStatusType,
  DeliveryInstructionStatusMetadata
> = {
  Draft: { type: 'Draft', order: 0, label: 'Draft', severity: 'info' },
  Pending: { type: 'Pending', order: 10, label: 'Pending', severity: 'warning' },
  InProgress: {
    type: 'InProgress',
    order: 20,
    label: 'In Progress',
    severity: 'info',
  },
  Dispatched: {
    type: 'Dispatched',
    order: 30,
    label: 'Dispatched',
    severity: 'success',
  },
  Cancelled: {
    type: 'Cancelled',
    order: 40,
    label: 'Cancelled',
    severity: 'danger',
  },
};
