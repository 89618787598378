<form [formGroup]="form"
      class="mt-2">
  <div class="flex justify-between">
    <div>
      <span class="font-semibold">Vessel: </span>
      <span>{{ _request?.vesselName || _request?.rigCode || _request?.vesselCode }}
        <span *ngIf="_request?.vesselName">({{ _request?.rigCode || _request?.vesselCode }})</span>
      </span>
    </div>
    <div class="ml-2">
      <span class="font-semibold">Customer: </span><span>{{ _request?.customerName }}</span>
    </div>
  </div>
  <div class="card bg-primary-50 mt-2 p-4 relative">
    <prt-block-overlay *ngIf="_request?.isLoading"
                       [opacity]="10"></prt-block-overlay>
    <div class="flex justify-start space-x-5 mb-2 text-sm">
      <div class="form-section-input-group w-44">
        <label for="expectedDeliveryDateFormatted">Exp Delivery Date/Time</label>
        <p-calendar styleClass="w-full"
                    formControlName="expectedDeliveryDateFormatted"
                    name="expectedDeliveryDateFormatted"
                    [showTime]="true"
                    selectionMode="single"
                    [hideOnDateTimeSelect]="true"
                    [showIcon]="true"
                    placeholder="Expected On"
                    [dateFormat]="Constants.inputCalendarFormat"
                    [selectOtherMonths]="true"
                    [appendTo]="(layoutService.rootScrolling$ | async)?.nativeElement"
                    [monthNavigator]="true"
                    [yearNavigator]="true"
                    [yearRange]="Constants.yearRangeDefault">
        </p-calendar>
      </div>
      <div class="form-section-input-group w-44">
        <label for="modeOfTransport">Pref Mode Of Transport</label>
        <p-dropdown styleClass="w-full"
                    [options]="modesOfTransport"
                    formControlName="modeOfTransport"
                    placeholder="Preferred MOT"></p-dropdown>
      </div>
    </div>

    <prt-delivery-request-packages-grid [packages]="_request?.packages || []"
                                        [request]="_request"
                                        [showValidation]="true"
                                        (remove)="removePackage($event)"
                                        (selectionUpdate)="onSelectionUpdate($event)"></prt-delivery-request-packages-grid>

    <div *ngIf="form.invalid"
         class="text-error mt-2 text-sm">
      <div *ngIf="form.errors?.noPackagesSelected">* No packages selected</div>
      <div *ngIf="form.errors?.invalidPackages">
        * Some packages are not eligible for delivery request any more. Please
        remove them from cart before proceeding.
      </div>
      <div *ngIf="
          modeOfTransportControl?.errors?.required ||
          expectedDeliveryDateFormattedControl?.errors?.required
        ">
        * Please input all mandatory fields.
      </div>
    </div>

    <div class="flex space-x-3 mt-3">
      <textarea pInputTextarea
                formControlName="gacInstruction"
                [rows]="3"
                style="resize: none"
                placeholder="Instruction to GAC"
                class="w-full"></textarea>

      <textarea pInputTextarea
                resize
                formControlName="customsInstruction"
                style="resize: none"
                [rows]="3"
                placeholder="Instruction to Customs"
                class="w-full"></textarea>

      <div class="flex flex-col w-[34rem]">
        <button pButton
                (click)="save()"
                [disabled]="
            isSaveInProgress ||
            isSendInProgress ||
            !_request ||
            _request.isLoading
          "
                label="Save"
                icon="pi {{ !isSaveInProgress ? 'pi-save' : 'pi-spin pi-spinner' }}"
                class="p-button-primary mb-1"></button>
        <button pButton
                (click)="send($event)"
                [disabled]="
            isSaveInProgress ||
            isSendInProgress ||
            !_request ||
            _request.isLoading
          "
                icon="pi {{ !isSendInProgress ? 'pi-send' : 'pi-spin pi-spinner' }}"
                label="Request Delivery"
                class="p-button-success"></button>
        <p-confirmPopup [key]="'sendRequest-' + _request!.id"
                        [styleClass]="'whitespace-pre-line custom-confirm'"></p-confirmPopup>
      </div>
    </div>
  </div>
</form>