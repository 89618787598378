<div class="pr-2 py-1">
  <h1 class="font-bold text-xl-plus flex items-center leading-10 tracking-wide-display flex-wrap">
    <span class="border border-transparent">Delivery Cart ({{cart.length}})</span>
  </h1>

  <div *ngFor="let cartItem of deliveryRequestCartService.deliveryRequestSuccess$ | async trackBy:trackById"
       [@appearAnimation]>
    <div class="card bg-primary mt-2 mb-6 relative p-0">
      <div class="header bg-green-400 text-primary-50 relative p-2 flex">
        <div class="flex-1 ml-4 flex items-center justify-center text-xl">Vessel: {{cartItem.vesselName || cartItem.rigCode || cartItem.vesselCode}}</div>
        <button class="inline-flex items-center justify-center p-2 rounded-full hover:bg-green-500 focus:outline-none focus:ring-1 focus:ring-white"
                (click)="closeSuccessMessage(cartItem)">
          <svg class="h-6 w-6"
               xmlns="http://www.w3.org/2000/svg"
               fill="none"
               viewBox="0 0 24 24"
               stroke="currentColor"
               aria-hidden="true">
            <path stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
      <div class="content p-12 flex flex-col justify-center items-center space-y-5">
        <div class="text-green-500 font-semibold text-lg">
          Your request for delivery has been confirmed!
        </div>
        <div>Your Delivery Request confirmation is on it's way to your registered email.</div>
        <div class="flex items-center">
          Reference No: <span class="font-semibold ml-2">{{cartItem?.customerReferenceNumber}}</span>
          <prt-copy-to-clipboard [text]="cartItem?.customerReferenceNumber"
                                 class="ml-2"></prt-copy-to-clipboard>
        </div>
      </div>
    </div>
  </div>

  <div *ngFor="let cartItem of cart trackBy:trackById">
    <prt-delivery-request-cart-form [request]="cartItem"></prt-delivery-request-cart-form>
  </div>
  <div *ngIf="!cart.length">
    <div class="mt-4">
      <div class="text-center mt-4">
        <span class="text-primary-400">{{deliveryRequestCartService.serverError ? 'Cart is not available at the moment': 'Your delivery cart is empty'}}</span>
      </div>
    </div>
  </div>
</div>