import { PurchaseOrderPackagesGridComponent } from './components/purchase-order-packages-grid/purchase-order-packages-grid.component';
import { ShipmentPackagesDialogComponent } from './components/shipment-packages-dialog/shipment-packages-dialog.component';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { ButtonModule } from 'primeng/button';
import { TimelineModule } from 'primeng/timeline';
import { ProgressBarModule } from 'primeng/progressbar';
import { TableModule } from 'primeng/table';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputTextModule } from 'primeng/inputtext';
import { SharedModule } from '../shared/shared.module';
import { PurchaseOrderLineItemsComponent } from './components/purchase-order-line-items/purchase-order-line-items.component';
import { ShipmentTrailMiniComponent } from './components/shipment-trail-mini/shipment-trail-mini.component';
import { WarehouseTableComponent } from './components/warehouse-table/warehouse-table.component';
import { ShipmentCargoComponent } from './components/shipment-cargo/shipment-cargo.component';
import { ShipmentPurchaseOrdersGridComponent } from './components/shipment-purchase-orders-grid/shipment-purchase-orders-grid.component';
import { WarehouseTablePackagesComponent } from './components/warehouse-table/warehouse-table-packages/warehouse-table-packages.component';
import { PurchaseOrderSearchProfileServiceService } from './services/purchase-order-search-profile-service.service';

@NgModule({
  declarations: [
    PurchaseOrderLineItemsComponent,
    PurchaseOrderPackagesGridComponent,
    ShipmentTrailMiniComponent,
    WarehouseTableComponent,
    ShipmentCargoComponent,
    ShipmentPackagesDialogComponent,
    ShipmentPurchaseOrdersGridComponent,
    WarehouseTablePackagesComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    TableModule,
    ProgressBarModule,
    InputTextModule,
    TimelineModule,
    ButtonModule,
    OverlayPanelModule,
  ],
  exports: [
    PurchaseOrderLineItemsComponent,
    PurchaseOrderPackagesGridComponent,
    ShipmentTrailMiniComponent,
    WarehouseTableComponent,
    ShipmentCargoComponent,
    ShipmentPackagesDialogComponent,
    ShipmentPurchaseOrdersGridComponent
  ],
  providers: [
    PurchaseOrderSearchProfileServiceService
  ]

})
export class SharedLogisticsModule { }
