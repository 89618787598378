export const OperationalProcessCode = {
  PortToPortImport: "OP0001",
  PortToPortExport: "OP0002",
  LandTransport: "OP0003",
  CustomsClearance: "OP0004",
  WarehouseIn: "OP0005",
  WarehouseOut: "OP0006",
  VesselScheduling: "OP0007",
  PreArrival: "OP0008",
  ServiceProvisionShipAgency: "OP0009",
  ServiceProvisionHusbandry: "OP0010",
};

export enum OperationalProcessType {
  LandTransport = 1,
  CustomsClearance = 2,
  PortToPortImport = 3,
  PortToPortExport = 4,
  WarehouseIn = 5,
  WarehouseOut = 6,
  PreArrival = 7,
  VesselScheduling = 8,
  ServiceProvisionShipAgency = 9,
  ServiceProvisionHusbandry = 10
}