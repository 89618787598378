import { CacheService } from 'src/app/core/services/cache.service';
import { IPackage } from "src/app/shared/model/order/package.interface";
import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { TableColumnsConfig } from 'src/app/shared/model/table-columns-config.model';
import { DynamicDialogConfig, DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { map } from 'rxjs/operators';
import { Photo } from 'src/app/shared/model/photo.model';
import { GalleryDialogComponent } from 'src/app/shared/components/gallery-dialog/gallery-dialog.component';

@Component({
  selector: 'prt-shipment-packages-dialog',
  templateUrl: './shipment-packages-dialog.component.html',
  styleUrls: ['./shipment-packages-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ShipmentPackagesDialogComponent implements OnInit {
  dialogRef?: DynamicDialogRef;

  packages: IPackage[] = [];

  columnsConfig: TableColumnsConfig[] = [
    // { field: 'description', header: 'Description' }, //removing for legacy
    { field: 'packageTypeName', header: 'PACKAGE TYPE', headerClass: '!flex-none w-44' },
    { field: 'grossWeight', header: 'GROSS WEIGHT', headerClass: '!flex-none w-44' },
    { field: 'grossVolume', header: 'GROSS VOLUME', headerClass: '!flex-none w-44' },
    { field: 'dimensions', header: 'DIMENSIONS (L*W*H)', headerClass: '!flex-none w-44' },
    { field: 'totalQuantity', header: 'QUANTITY', class: "text-right", headerClass: '!flex-none w-44' },
    { field: 'photo', header: 'PHOTO', headerClass: '!flex-none w-28' }
  ];

  constructor(
    private config: DynamicDialogConfig,
    private dialogService: DialogService,
    private cacheService: CacheService
  ) { }

  ngOnInit(): void {
    let packages: IPackage[] = this.config.data.packages?.map((packet: IPackage) => {
      let p: IPackage = { ...packet };
      p.volumeUom$ = this.cacheService.getUomLookup()
        .pipe(map(uoms => uoms.find(u => u.code === p.volumeUom) || { symbol: '', name: p.volumeUomName }),
          map(u => u?.symbol || u?.name));
      p.weightUom$ = this.cacheService.getUomLookup()
        .pipe(map(uoms => uoms.find(u => u.code === p.weightUom) || { symbol: '', name: p.weightUomName }),
          map(u => u?.symbol || u?.name));
      p.dimensionUom$ = this.cacheService.getUomLookup()
        .pipe(map(uoms => uoms.find(u => u.code === p.dimensionUoMcode) || { symbol: '', name: p.dimensionUoMname }),
          map(u => u?.symbol || u?.name));
      p.grossVolume = p.grossVolume == null ? p.volume : p.grossVolume;
      p.grossWeight = p.grossWeight == null ? p.weight : p.grossWeight;
      return p;
    }) || [];

    //keep only distinct
    let packageIds: number[] = [];
    let rootPackages: IPackage[] = [];

    //find all which don't belong to overpacks
    packages.forEach(p => {
      if (!p.parentPackageId) {
        if (packageIds.indexOf(p.packageId) === -1) {
          packageIds.push(p.packageId);
          rootPackages.push(p);
        }
      }
    });

    //find all which belong to overpack and place them inside
    packages.forEach(p => {
      if (p.parentPackageId) {
        if (packageIds.indexOf(p.packageId) === -1) {
          let overpack = rootPackages.find(overpack => overpack.packageId === p.parentPackageId);
          if (overpack) {
            if (!overpack.packages) {
              overpack.packages = [];
            }
            packageIds.push(p.packageId);
            overpack.packages.push(p);
          }
          //it could be import so it will have overpack fk but overpack is not there
          else {
            packageIds.push(p.packageId);
            rootPackages.push(p);
          }
        }
      }
    });

    this.packages = rootPackages;
  }

  openGallery(p: IPackage) {
    let config = {
      data: {
        photos: p.documents.map(d => {
          return {
            name: d.name,
            extension: d.extension,
            documentId: d.documentId,
            module: d.moduleName,
          } as Photo;
        })
      },
      dismissableMask: true,
      contentStyle: { 'padding': '0', 'flex': '1' },
      header: 'Package Photos',
      styleClass: 'gallery-dialog',
      baseZIndex: 11000

    } as DynamicDialogConfig;
    this.dialogRef = this.dialogService.open(GalleryDialogComponent, config);
  }

  ngOnDestroy(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }
}
