<div *ngIf="!_data">Loading...</div>


<div class="purchase-order-line-items"
     *ngIf="!!_data">
  <div class="flex items-center text-sm font-medium text-primary-700 mb-4"
       *ngIf="showHeading">
    <img [src]="headingIconUrl || '/assets/images/crate.svg'"
         alt="crate"
         class="w-5 h-5 mr-3" />
    {{heading||'LINE ITEMS'}} <span class="text-primary-400 ml-3">({{_data!.length}})</span>
  </div>
  <p-table [columns]="columnsConfig"
           [value]="_data"
           class="gray-header-table da-table po-table table-border-bottom-none"
           [autoLayout]=true>


    <ng-template pTemplate="header"
                 let-columns>
      <tr>
        <ng-container *ngFor="let col of columnsConfig">
          <th class="{{col.headerClass}} py-4">
            {{col.header}}
          </th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body"
                 let-lineItem
                 let-columns="columns">
      <tr (click)=onRowClick(lineItem)
          (keydown.enter)=onRowClick(lineItem)
          [class.cursor-pointer]="selectable">
        <ng-container *ngFor="let col of columns">
          <td>

            <ng-container [ngSwitch]="col.field">
              <!-- to be styled -->
              <div *ngSwitchCase="'partNumber'"
                   class="flex flex-col">
                <div>{{lineItem.partNumber}}</div>
                <div class="flex items-center mt-1"
                     *ngIf="lineItem.partDescription"
                     pTooltip="{{lineItem.partDescription}}"
                     tooltipPosition="bottom">
                  <div class="overflow-ellipsis whitespace-nowrap overflow-hidden text-primary-400 font-display"
                       style="max-width: 18ch;">{{lineItem.partDescription}}</div>
                </div>
              </div>

              <div *ngSwitchCase="'externalPartNumber'"
                   class="flex flex-col">
                <div>{{lineItem.externalPartNumber || lineItem.lineNumber}}</div>
              </div>

              <div *ngSwitchCase="'shipment'"
                   class="flex items-center">
                <img src="{{$any(transportMode)[lineItem.operationalProcess?.configuration?.transportationMode]?.imageUrl}}"
                     alt="transport mode"
                     class="mr-3 w-4" />
                <div class="shipment-progress-small">
                  <div class="from-to">
                    <span>{{lineItem.operationalProcess?.configuration?.originCode}}</span>
                    <span>{{lineItem.operationalProcess?.configuration?.destinationCode}}</span>
                  </div>
                  <p-progressBar class="progress-bar"
                                 [value]=lineItem.operationalProcess?.configuration?.progress
                                 [showValue]=false></p-progressBar>
                </div>
              </div>
              <div *ngSwitchCase="'isHazardous'"
                   class="flex items-center">
                <i class="pi pi-exclamation-triangle text-red-600"
                   *ngIf="lineItem.isHazardous"></i>
                <span *ngIf="!lineItem.isHazardous">N/A</span>
              </div>
              <div *ngSwitchCase="'photos'"
                   class="flex">
                <ng-container *ngIf="lineItem.photos?.length > 0"></ng-container>
                <img *ngFor="let x of [].constructor(Math.min(lineItem.photos.length, 3));  let i = index"
                     src="{{lineItem.photos[i].thumbnailUrl}}"
                     alt="line item thumbnail"
                     class="table-thumbnail mr-1" />
                <ng-container *ngIf="lineItem.photos?.length > 3">
                  +{{lineItem.photos.length-3}}
                </ng-container>
              </div>

              <div *ngSwitchCase="'crates'"
                   class="flex">
                <ng-container *ngIf="lineItem.mr > 0">
                  <img src="assets/images/crate.svg"
                       alt="crate"
                       class="w-4-plus h-4-plus mr-1"
                       *ngFor="let x of [].constructor(Math.min(lineItem.mr, 3));  let i = index" />
                  <ng-container *ngIf="lineItem.mr > 3">
                    +{{lineItem.mr-3}}
                  </ng-container>
                </ng-container>
              </div>

              <ng-container *ngSwitchDefault>
                <prt-table-cell [model]=lineItem
                                [columnConfig]=col></prt-table-cell>
              </ng-container>
            </ng-container>
          </td>
        </ng-container>
      </tr>
    </ng-template>
  </p-table>
</div>