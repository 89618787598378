export const PurchaseOrderLineItemsColumns = {
  partNumber: 'partNumber',
  externalPartNumber: 'externalPartNumber',
  partDescription: 'partDescription',
  cargoSupplierName: 'cargoSupplierName',
  uomCode: 'uomCode',
  expectedQuantity: 'expectedQuantity',
  receivedQuantity: 'receivedQuantity',
  lineStatus: 'lineStatusName',
  isHazardous: 'isHazardous',
  readinessDate: 'readinessDate',
  expiryDate: 'expiryDate',
  requiredDeliveryDate: 'requiredDeliveryDate',
};