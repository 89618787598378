<prt-table-cell-templates></prt-table-cell-templates>
<div *ngIf="!_data">Loading...</div>

<div class="purchase-order-items"
     *ngIf="!!_data">
  <div class="flex items-center text-sm font-medium text-primary-700 mb-4"
       *ngIf="showHeading">
    <img [src]="headingIconUrl || '/assets/images/crate.svg'"
         class="w-5 h-5 mr-3" />
    {{heading||'PACKAGES'}} <span class="text-primary-400 ml-3">({{_data!.length}})</span>
  </div>
  <p-table [columns]="columnsConfig"
           [value]="_data"
           dataKey="packageId"
           class="gray-header-table da-table po-table table-border-bottom-none"
           [autoLayout]=true>


    <ng-template pTemplate="header"
                 let-columns>
      <tr>
        <ng-container *ngFor="let col of columnsConfig; let i = index">
          <th class="{{col.headerClass}} py-4"
              *ngIf="!col.hide"
              [attr.colspan]="i==0 ? 2:1">
            {{col.header}}
            <ng-container *ngIf="col.field=='isSelectedForDelivery'">
              <ng-container *ngIf="!loadingPackageState; else loader">
                <p-checkbox class="mt-2 -mb-2"
                            [disabled]="!eligibleForSelection.length"
                            [readonly]="!eligibleForSelection.length"
                            [ngModel]="areAllSelected || (!!_data.length && !eligibleForSelection.length)"
                            (onChange)="onPackageDeliveryAllToggle()"
                            [value]="areAllSelected"
                            [binary]="true"></p-checkbox>
              </ng-container>
              <ng-template #loader>
                <div class="pt-1.5 mt-2 -mb-2 flex h-[20px] w-[20p] items-center justify-center">
                  <i class="pi pi-spin pi-spinner"></i>
                </div>
              </ng-template>
            </ng-container>

          </th>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="body"
                 let-package
                 let-columns="columns"
                 let-expanded="expanded">

      <tr [class.cursor-pointer]="selectable">
        <td style="width: 2rem;padding-right:0;padding-left:0;">
          <a href="#"
             [pRowToggler]="package"
             *ngIf="package.cargoLines && package.cargoLines.length">
            <i class="text-primary-200"
               [ngClass]="expanded ? 'pi pi-fw pi-chevron-down' : 'pi pi-fw pi-chevron-right'"></i>
          </a>
        </td>
        <ng-container *ngFor="let col of columns">

          <td (click)="onRowClick(package)"
              class="{{col.class}}"
              *ngIf="!col.hide">
            <ng-container [ngSwitch]="col.field">
              <ng-container *ngSwitchCase="'dimensions'">
                <div>
                  <span *ngIf="package.volume"
                        class="mr-2">
                    <span class="uppercase text-xs text-primary-300 w-14 inline-block">Volume</span>
                    {{package.volume}} {{package.volumeUomName}}
                  </span>
                  <span *ngIf="package.weight"
                        class="mr-2">
                    <span class="uppercase text-xs text-primary-300 w-14 inline-block">Weight</span>
                    {{package.weight}} {{package.weightUomName}}
                  </span>
                </div>
                <div>
                  <span *ngIf="package.length"
                        class="mr-2">
                    <span class="uppercase text-xs text-primary-300 w-14 inline-block">Length</span>
                    {{package.length}} {{package.dimensionUoMname}}
                  </span>
                  <span *ngIf="package.width"
                        class="mr-2">
                    <span class="uppercase text-xs text-primary-300 w-14 inline-block">Width</span>
                    {{package.width}} {{package.dimensionUoMname}}
                  </span>
                  <span *ngIf="package.height"
                        class="mr-2">
                    <span class="uppercase text-xs text-primary-300 w-14 inline-block">Height</span>
                    {{package.height}} {{package.dimensionUoMname}}
                  </span>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'shipment'">
                <div class="flex items-center"
                     *ngIf="package.shipmentInfo">
                  <img src="{{$any(modeOfTransport)[package.shipmentInfo.modeOfTransport].imageUrl}}"
                       class="mr-3 w-4" />
                  <div class="shipment-progress-small"
                       style="width: 180px">
                    <div class="from-to flex justify-between">
                      <span class="overflow-hidden overflow-ellipsis"
                            style="max-height: 3em; flex:0 0 10ch;"
                            [pTooltip]="package.shipmentInfo.origin.locationName"
                            tooltipPosition="top"
                            tooltipStyleClass="-mt-2">{{package.shipmentInfo.origin.locationDisplayName}}</span>
                      <span class="overflow-hidden overflow-ellipsis text-right"
                            style="max-height: 3em; flex:0 0 10ch;"
                            [pTooltip]="package.shipmentInfo.destination.locationName"
                            tooltipPosition="top"
                            tooltipStyleClass="-mt-2">{{package.shipmentInfo.destination.locationDisplayName}}</span>
                    </div>
                    <!-- todo -->
                    <p-progressBar class="progress-bar"
                                   [value]=package.shipmentInfo.progress
                                   [showValue]=false></p-progressBar>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngSwitchCase="'packageTypeName'">
                <span>{{ package.packageTypeName | singularize }}</span>
              </ng-container>
              <ng-container *ngSwitchCase="'packageLocation'">
                <span>
                  {{ package.packageLocationName || package.packageLocation }}</span>
              </ng-container>
              <ng-container *ngSwitchCase="'isSelectedForDelivery'">
                <span class="{{col.class}}"
                      (click)="$event.stopPropagation()">
                  <ng-container *ngIf="!loadingPackageState; else loader">
                    <p-checkbox [readonly]="!isDeliverySelectionEnabled(package)"
                                [disabled]="!isDeliverySelectionEnabled(package)"
                                [ngModel]="package[col.field] || package.isDeliveryRequested"
                                (onChange)="onPackageDeliveryToggle(package)"
                                [value]="package[col.field]"
                                [binary]="true"></p-checkbox>
                  </ng-container>
                  <ng-template #loader>
                    <div class="flex pt-1.5 h-[20px] w-[20p] items-center justify-center">
                      <i class="pi pi-spin pi-spinner"></i>
                    </div>
                  </ng-template>
                </span>
              </ng-container>
              <ng-container *ngSwitchDefault>
                <ng-container *ngTemplateOutlet="$any(tableCellTemplates)[col.type || 'primitive']; context: {model: package, col: col, vm: tableCellTemplates}"></ng-container>
              </ng-container>
            </ng-container>
          </td>
        </ng-container>
      </tr>
    </ng-template>
    <ng-template pTemplate="rowexpansion"
                 let-package
                 let-columns="columns">
      <tr>
        <td class="bg-primary-50 pl-5 py-4-plus pr-5 sub-table"
            [attr.colspan]="columnsLength + 2">
          <div class="card mt-0">
            <prt-purchase-order-line-items [data]="package.cargoLines"
                                           *ngIf="package.cargoLines && package.cargoLines.length"
                                           [showHeading]="false"
                                           [showShipment]="false"
                                           [selectable]="false"></prt-purchase-order-line-items>
          </div>
        </td>
      </tr>
    </ng-template>
  </p-table>

  <div *ngIf="_showDelivery && !!eligibleForSelection.length"
       class="flex justify-end mt-7">
    <button pButton
            type="button"
            label="{{anyInCartDeliveryRequest ?  'Update' : 'Add To' }} Delivery Cart"
            class="p-button ml-2"
            [disabled]="loadingPackageState"
            (click)="addToDeliveryCart()"></button>
  </div>
</div>
<ng-template #rigCodeTemplate
             let-package="model">
  <div>
    <span *ngIf="package?.isFleetStock"
          [pTooltip]="'Fleet Stock'"
          tooltipPosition="top"
          tooltipStyleClass="-mt-2">
      <img [src]="'/assets/images/crateVerified.svg'"
           class="w-6 h-6 mr-2 inline" />N/A</span>
    <span *ngIf="!(package?.isFleetStock)">{{package.rigCode || package.vesselCode}}</span>
  </div>
</ng-template>
<ng-template #vesselNameTemplate
             let-package="model">
  <div class="font-normal text-sm"
       *ngIf="!package.isFleetStock">{{package.vesselName}}&nbsp;</div>
</ng-template>