import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AutoComplete } from 'primeng/autocomplete';
import { Subject, of } from 'rxjs';
import { catchError, skipWhile, takeUntil } from 'rxjs/operators';
import { CacheService } from '../core/services/cache.service';
import { HelperService } from '../core/services/helper.service';
import { LayoutService } from '../layout/layout.service';
import { DeliveryInstructionStatuses } from '../shared/model/delivery-instruction/delivery-instruction-status.enum';
import { DeliveryInstruction } from '../shared/model/delivery-instruction/delivery-instruction.dto';
import { DeliveryInstructionsQueryDto } from '../shared/model/delivery-instruction/delivery-instructions-query.dto';
import { MasterData } from '../shared/model/master-data/master-data.model';
import { PageResponseDto } from '../shared/model/page-response.dto';
import { DateFormat, DateHelperService } from '../shared/services/date-helper.service';
import { Constants } from './../core/services/constants.service';
import { DeliveryRequestDataService } from './delivery-request-data.service';
import { SORT_OPTIONS, SORT_VALUES } from './delivery-requests.constants';

@Component({
  templateUrl: './delivery-requests.component.html',
  styleUrls: ['./delivery-requests.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DeliveryRequestsComponent implements OnInit {
  private readonly _destroying$ = new Subject<void>();
  Constants = Constants;
  data: DeliveryInstruction[] = [];
  isLoading: boolean = true;
  isLoadingLookups: boolean = true;
  isFinalPageLoaded = false;
  vesselOptions: MasterData[] = [];
  vesselSuggestions: MasterData[] = [];
  statusOptions = Object.values(DeliveryInstructionStatuses)
    .filter((x) => x !== DeliveryInstructionStatuses.Draft)
    .map((x) => {
      return { label: x.label, value: x.type };
    });
  emptyFiltersForm = {
    requestedFrom: null,
    requestedTo: null,
    rig: null,
    statuses: null,
    query: null,
    sortAsc: false,
  };
  filtersForm: UntypedFormGroup = this.formBuilder.group(this.emptyFiltersForm);
  queryTextForm: UntypedFormGroup = this.formBuilder.group({ queryText: null });
  queryDto = new DeliveryInstructionsQueryDto(
    '',
    null,
    null,
    null,
    null,
    50,
    null,
    null,
    null
  );
  lastQueryGuid?: string;

  sortOptions: any[] = SORT_OPTIONS;
  selectedSortOption = null;

  constructor(
    private deliveryRequestDataService: DeliveryRequestDataService,
    public helperService: HelperService,
    private formBuilder: UntypedFormBuilder,
    private cacheService: CacheService,
    public layoutService: LayoutService
  ) { }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  ngOnInit(): void {
    this.initLookups();
    this.trackFiltersFormChange();
  }

  trackFiltersFormChange() {
    this.filtersForm.valueChanges
      .pipe(
        skipWhile((x) => this.isLoadingLookups),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.queryDto = {
          ...this.queryDto,
          ...this.filtersForm.value,
          rigCode: this.filtersForm.value.rig?.code,
          requestedFrom: DateHelperService.format(this.filtersForm.value.requestedFrom, DateFormat['YYYY-MM-DD']),
          requestedTo: DateHelperService.format(this.filtersForm.value.requestedTo, DateFormat['YYYY-MM-DD']),
        };
        this.search();
      });
  }

  initLookups() {
    this.cacheService
      .getOwnerVesselAndRigInfo()
      .pipe(takeUntil(this._destroying$))
      .subscribe((vessels) => {
        if (vessels) {
          this.vesselOptions = vessels;
          this.vesselSuggestions = vessels;
          this.isLoadingLookups = false;
          this.load();
        }
      });
  }

  searchVessels(query: string) {
    if (query != null) {
      this.vesselSuggestions = this.vesselOptions.filter((vessel: MasterData) => {
        return vessel.name.toLowerCase().includes(query.toLowerCase());
      });
    }
  }

  clearSearchInput(): void {
    this.queryTextForm.setValue({ queryText: null }, { onlySelf: false });
    this.onQueryTextSubmit();
  }

  clearAutocomplete(formField: string) {
    this.filtersForm.get(formField)?.patchValue(null, { onlySelf: false });
  }

  onDropdownFocus(el: AutoComplete) {
    el?.dropdownButton?.nativeElement.setAttribute('tabindex', -1);
  }

  clearAllFilters() {
    this.filtersForm.setValue(this.emptyFiltersForm);
    this.queryTextForm.setValue({ queryText: null });
  }

  onQueryTextSubmit() {
    this.filtersForm.patchValue({ query: this.queryTextForm.value?.queryText });
  }

  onSort(e: any) {
    const { value: optionValue } = e.value;

    switch (optionValue) {
      case SORT_VALUES.ASC:
        this.filtersForm.patchValue({ sortAsc: true });
        break;
      case SORT_VALUES.DESC:
        this.filtersForm.patchValue({ sortAsc: null });
        break;
      default:
        this.filtersForm.patchValue({ sortAsc: null });
        break;
    }
  }

  search(): void {
    this.data = [];
    this.queryDto.continuationToken = null;
    this.isFinalPageLoaded = false;
    this.load();
  }

  load(): void {
    if (this.isLoadingLookups) {
      return;
    }
    this.isLoading = true;
    let queryGuid = this.helperService.getNewGuid();
    this.lastQueryGuid = queryGuid;
    this.deliveryRequestDataService
      .getAll(this.queryDto)
      .pipe(
        takeUntil(this._destroying$),
        catchError(() => of(new PageResponseDto<DeliveryInstruction>()))
      )
      .subscribe((data) => {
        if (queryGuid !== this.lastQueryGuid) {
          return;
        }
        this.isLoading = false;
        this.queryDto.continuationToken = data.continuationToken;
        this.setIsFinalPageLoadedFromResponse(data);
        this.data = [...this.data, ...data.items];
      });
  }

  setIsFinalPageLoadedFromResponse(
    response: PageResponseDto<DeliveryInstruction>
  ) {
    if (
      response.items.length < this.queryDto.pageSize ||
      !response.continuationToken
    ) {
      this.isFinalPageLoaded = true;
    }
  }

  onScroll() {
    if (this.isLoading || this.isFinalPageLoaded) {
      return;
    }
    this.load();
  }

  onBottom(event: any) {
    if (event.visible) {
      this.onScroll();
    }
  }
}
