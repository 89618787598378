
export class DeliveryInstructionsQueryDto {
  constructor(
    public query: string,
    public requestedFrom: Date | null,
    public requestedTo: Date | null,
    public rigCode: string | null,
    public statuses: string[] | null,
    public pageSize: number,
    public continuationToken: string | null,
    public sortBy: string | null,
    public sortAsc: boolean | null
  ) { }
}