<div class="prt-container relative delivery-requests"
     infinite-scroll
     [infiniteScrollDistance]="2"
     [infiniteScrollThrottle]="150"
     [alwaysCallback]="false"
     [infiniteScrollContainer]="'.root-scrolling-container'"
     [fromRoot]="true"
     [scrollWindow]="false"
     [infiniteScrollDisabled]="isFinalPageLoaded"
     (scrolled)="onScroll()">
  <div class="card px-7 md:px-8 xl:px-10 md:py-10">
    <div class="flex justify-between items-center mb-10">
      <h1 class="font-bold">Delivery Requests</h1>

      <form [formGroup]="queryTextForm"
            (ngSubmit)="onQueryTextSubmit()"
            class="flex items-stretch gap-2">
        <div class="filter-group w-72 flex-1 flex-shrink-0 lg:max-w-[300px]">
          <div class="p-inputgroup search-inputgroup flex-1">
            <div class="p-input-icon-right w-full flex p-inputgroup force-hover">
              <input type="text"
                     pInputText
                     formControlName="queryText"
                     class="pr-2"
                     placeholder="Search POs / Packages" />
              <i [class.hidden]="!queryTextForm?.value?.queryText"
                 (click)="clearSearchInput()"
                 class="p-dropdown-clear-icon pi pi-times z-10 cursor-pointer hover:text-primary-300"></i>
            </div>
            <button type="submit"
                    pButton
                    class="p-button-secondary search p-button-sm"
                    icon="pi pi-search"
                    iconPos="right"
                    styleClass=""></button>
          </div>
        </div>
        <div class="filter-group flex-initial flex-shrink-0">
          <button pButton
                  type="button"
                  label="Clear All"
                  icon="pi pi-refresh sm-max:mr-1"
                  iconPos="left"
                  class="p-button-sm p-button-secondary sm-max:tracking-tight sm-max:pl-1 sm-max:text-xs flex-1 h-full"
                  (click)="clearAllFilters()"></button>
        </div>
      </form>
    </div>

    <div class="filters flex flex-col md:flex-row flex-wrap gap-x-8 gap-y-2">
      <form [formGroup]="filtersForm"
            class="flex flex-col sm:flex-row flex-1 gap-2">
        <div class="filter-group min-w-[10rem] flex-1 flex-shrink-0">
          <div class="relative">
            <p-autoComplete #rigCode
                            styleClass="w-full"
                            scrollHeight="300px"
                            formControlName="rig"
                            (completeMethod)="searchVessels($event.query)"
                            [suggestions]="vesselSuggestions"
                            [dropdown]="true"
                            [dropdownIcon]="isLoadingLookups ? 'pi pi-spin pi-spinner' : 'pi pi-chevron-down'"
                            [forceSelection]="true"
                            [completeOnFocus]="true"
                            dataKey="code"
                            field="name"
                            [appendTo]="'body'"
                            tooltip="Vessel"
                            [autoHighlight]="true"
                            (onFocus)="onDropdownFocus(rigCode)"
                            placeholder="Vessel">
              <ng-template let-vessel
                           pTemplate="item">
                <div>{{ vessel.name }}</div>
                <div class="text-primary-400">{{ vessel.code }}</div>
              </ng-template></p-autoComplete>
            <div class="autocomplete-clear"
                 *ngIf="!!filtersForm?.value?.rigCode">
              <i class="clear-icon pi pi-times"
                 (click)="clearAutocomplete('rigCode')"></i>
            </div>
          </div>
        </div>

        <!-- todo dates -->
        <div class="filter-group w-48">
          <div class="relative">
            <p-calendar name="requestedFrom"
                        [showTime]="false"
                        formControlName="requestedFrom"
                        selectionMode="single"
                        placeholder="Requested Date From"
                        [showIcon]="true"
                        [dateFormat]="Constants.inputCalendarFormat"
                        [selectOtherMonths]="true"
                        [appendTo]="(layoutService.rootScrolling$ | async)?.nativeElement"
                        [monthNavigator]="true"
                        [yearNavigator]="true"
                        [yearRange]="Constants.yearRangeDefault"></p-calendar>
            <div class="autocomplete-clear"
                 *ngIf="!!filtersForm?.value?.requestedFrom">
              <i class="clear-icon pi pi-times"
                 (click)="clearAutocomplete('requestedFrom')"></i>
            </div>
          </div>
        </div>
        <div class="filter-group w-60">
          <div class="relative">
            <p-calendar name="requestedTo"
                        [showTime]="false"
                        formControlName="requestedTo"
                        selectionMode="single"
                        placeholder="Requested Date To"
                        [showIcon]="true"
                        [dateFormat]="Constants.inputCalendarFormat"
                        [selectOtherMonths]="true"
                        [appendTo]="(layoutService.rootScrolling$ | async)?.nativeElement"
                        [monthNavigator]="true"
                        [yearNavigator]="true"
                        [yearRange]="Constants.yearRangeDefault"></p-calendar>
            <div class="autocomplete-clear"
                 *ngIf="!!filtersForm?.value?.requestedTo">
              <i class="clear-icon pi pi-times"
                 (click)="clearAutocomplete('requestedTo')"></i>
            </div>
          </div>
        </div>

        <div class="filter-group w-60">
          <div class="relative">
            <p-multiSelect styleClass="w-full"
                           inputStyleClass="w-full"
                           [options]="statusOptions"
                           [appendTo]="'body'"
                           [maxSelectedLabels]="1"
                           [selectedItemsLabel]="'{0} selected'"
                           [showToggleAll]="false"
                           [showHeader]="false"
                           [showClear]="false"
                           formControlName="statuses"
                           name="statuses"
                           [optionLabel]="'label'"
                           [optionValue]="'value'"
                           placeholder="Status">
            </p-multiSelect>
            <div class="multiselect-clear right-9"
                 *ngIf="!!filtersForm?.value?.statuses">
              <i class="clear-icon pi pi-times"
                 (click)="clearAutocomplete('statuses')"></i>
            </div>
          </div>
        </div>
      </form>
      <form [formGroup]="queryTextForm"
            (ngSubmit)="onQueryTextSubmit()"
            class="flex flex-col sm:flex-row flex-1 gap-2 justify-end">
        <div class="filter-group w-72 flex-initial flex-shrink-0">
          <p-dropdown [options]="sortOptions"
                      (onChange)="onSort($event)"
                      [(ngModel)]="selectedSortOption"
                      optionLabel="label"
                      styleClass="!w-full"
                      placeholder="Sort By"></p-dropdown>
        </div>
      </form>
    </div>

    <div class="overflow-x-auto my-3">
      <p-accordion class="w-full"
                   styleClass="flex flex-col">
        <prt-delivery-request class="mt-6"
                              *ngFor="let request of data; trackBy: helperService.trackById"
                              [deliveryRequest]="request"></prt-delivery-request>
      </p-accordion>
    </div>

    <!-- todo -->

    <div *ngIf="isLoading">

      <div class="card flex items-center"
           *ngFor="let x of [].constructor(2)">
        <p-skeleton width="30px"
                    height="30px"
                    shape="circle"
                    class="mr-3"></p-skeleton>
        <div class="flex flex-1 justify-between items-center gap-6">
          <div *ngFor="let x of [].constructor(3)"
               class="flex flex-[2] flex-col gap-3">
            <p-skeleton *ngFor="let x of [].constructor(2)"
                        width="100%"
                        height="34px"></p-skeleton>
          </div>
          <div class="flex-1">
            <p-skeleton width="100%"
                        shape="rounded"
                        height="18px"></p-skeleton>
          </div>
          <div class="flex-1">
            <p-skeleton width="100%"
                        height="30px"></p-skeleton>
          </div>
        </div>
      </div>
    </div>
    <div *ngIf="!isLoading"
         style="height: 15rem"></div>
    <div *ngIf="!isLoading && !data.length"
         class="text-primary-300 text-center">
      No items found.
    </div>
    <div *ngIf="!isLoading && isFinalPageLoaded && data.length > 0"
         class="text-primary-300 text-center mt-2">
      No more items to load.
    </div>
    <div inViewport
         [inViewportOptions]="{ threshold: [0] }"
         (inViewportAction)="onBottom($event)"></div>
  </div>
</div>