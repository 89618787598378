import { Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { of } from 'rxjs';
import { PortalCustomerConfigurationService } from 'src/app/core/services/portal-customer-configuration.service';
import { ShipmentDetails } from 'src/app/shared/model/shipment/shipment-details';
import { ShipmentCargo } from '../../model/shipment-cargo.model';
import { ShipmentPackagesDialogComponent } from '../shipment-packages-dialog/shipment-packages-dialog.component';

@Component({
  selector: 'prt-shipment-cargo',
  templateUrl: './shipment-cargo.component.html',
  styleUrls: ['./shipment-cargo.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [DialogService]
})
export class ShipmentCargoComponent implements OnDestroy {
  dialogRef?: DynamicDialogRef;
  packages: ShipmentCargo[] = [];
  _shipment: ShipmentDetails | null = null;

  @Input() set shipment(s: ShipmentDetails | null) {
    this._shipment = s;
    this.packages = [];
    let shipmentCargo: ShipmentCargo = {
      weight: [0, of('')],
      volume: [0, of('')],
      packageTypeList: {}
    };

    // for legacy, map shipment cargo from special node
    if (s?.shipmentDetails?.length) {
      s.shipmentDetails.forEach(sd => {
        shipmentCargo.weight[0] += sd.totalChargeableWeight || 0;
        if (sd.totalWeightUomCode && sd.weightUom$) {
          shipmentCargo.weight[1] = sd.weightUom$;
        }
        shipmentCargo.volume[0] += sd.totalVolume || 0;
        if (sd.totalVolumeUomCode && sd.volumeUom$) {
          shipmentCargo.volume[1] = sd.volumeUom$;
        }
        if (shipmentCargo.packageTypeList[sd.uomName || '']) {
          shipmentCargo.packageTypeList[sd.uomName || ''] += sd.quantity || 0;
        }
        else {
          shipmentCargo.packageTypeList[sd.uomName || ''] = sd.quantity || 0;
        }
      });
      this.packages.push(shipmentCargo);

    }

    //ordinary
    else if (s && s.packages?.length) {
      s.packages.filter(p => p.isActive).forEach(p => {
        shipmentCargo.weight[0] += p.grossWeight || 0;
        if (p.weightUomCode && p.weightUom$) {
          shipmentCargo.weight[1] = p.weightUom$;
        }
        shipmentCargo.volume[0] += (p.grossVolume == null ? p.volume : p.grossVolume) || 0;
        if (p.volumeUomCode && p.volumeUom$) {
          shipmentCargo.volume[1] = p.volumeUom$;
        }
        if (shipmentCargo.packageTypeList[p.packageUomName]) {
          shipmentCargo.packageTypeList[p.packageUomName] += p.quantity || 0;
        }
        else {
          shipmentCargo.packageTypeList[p.packageUomName] = p.quantity || 0;
        }

      });
      this.packages.push(shipmentCargo);
    }
  }

  constructor(
    private dialogService: DialogService,
    public portalCustomerConfigurationService: PortalCustomerConfigurationService,
  ) { }

  ngOnDestroy(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  showPackageDetails(event: UIEvent) {
    event.stopImmediatePropagation();
    let config: DynamicDialogConfig = {
      data: {
        packages: this._shipment?.poList.flatMap(po => po.packages),
      },
      dismissableMask: true,
      contentStyle: { 'padding': '0', 'height': '75vh' },
      header: 'Linked PO Package Details',
      styleClass: 'shipment-packages-dialog header-extra-left'
    };

    this.dialogRef = this.dialogService.open(ShipmentPackagesDialogComponent, config);
  }

}
