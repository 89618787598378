import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MessageService } from 'primeng/api';
import { Observable, Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { HelperService } from 'src/app/core/services/helper.service';
import { PurchaseOrderService } from 'src/app/purchase-orders/purchase-order/purchase-order.service';
import { WarehouseItem } from 'src/app/shared-logistics/model/warehouse-item.model';
import { OrderSource } from 'src/app/shared/model/order/order-source.enum';
import { IOrder } from 'src/app/shared/model/order/order.interface';
import { TableColumnsConfig } from 'src/app/shared/model/table-columns-config.model';
import { FilesService } from 'src/app/shared/services/files.service';
import { OrderDataService } from 'src/app/shared/services/order-data.service';


export interface IMrnGroup {
  mrNumber: string;
  numberOfPackages?: number;
  warehouseItems?: WarehouseItem[];
  source: OrderSource;
}

@Component({
  selector: 'prt-warehouse-table',
  templateUrl: './warehouse-table.component.html',
  styleUrls: ['./warehouse-table.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class WarehouseTableComponent implements OnInit, OnDestroy {
  OrderSource = OrderSource;
  private readonly _destroying$ = new Subject<void>();
  @Input() items$?: Observable<WarehouseItem[] | null>;
  @Input() isLoading?: boolean | null;
  @Input() heading?: string;
  expandedRowKeys: {
    [s: string]: boolean;
  } = {};

  cargoOfficeCode?: string;
  order: IOrder | null = null;

  warehouseItems: IMrnGroup[] = [];

  columnsConfig: TableColumnsConfig[] = [
    { field: 'mrNumber', header: 'MR NUMBER' },
    { field: 'numberOfPackages', header: 'TOTAL NO. OF PACKAGES' },
  ];

  constructor(
    private fileUploadService: FilesService,
    private helperService: HelperService,
    private messageService: MessageService,
    private orderDataService: OrderDataService,
    private purchaseOrderService: PurchaseOrderService) {
  }

  ngOnInit(): void {
    this.purchaseOrderService.salesOrder$.pipe(
      takeUntil(this._destroying$)
    ).subscribe(x => {
      this.order = x;
      this.cargoOfficeCode = x?.configuration?.cargo?.officeCode;
    });
    this.items$?.pipe(
      takeUntil(this._destroying$),
    ).subscribe(data => {
      if (!data) {
        return;
      }
      this.warehouseItems = this.groupDataByMrNumber(data) ?? [];
      data.filter(x => x.isSelected).forEach(x => { this.expandedRowKeys[x.mrNumber as string] = true; });
    });
  }

  ngOnDestroy(): void {
    this._destroying$.next();
    this._destroying$.complete();
  }

  generateMRDocument(item: WarehouseItem) {
    if (item.generatingDocument ||
      item.source != this.OrderSource.Legacy ||
      !item.mrNumber ||
      !this.cargoOfficeCode) {
      return;
    }
    item.generatingDocument = true;
    this.orderDataService.generateMRDocument(
      item.mrNumber as number,
      item.officeCode || this.cargoOfficeCode,
      this.order?.configuration?.cargo?.poNumber || "",
      this.order?.configuration?.cargo?.groupCustomerCode || "").subscribe(
        (file: Blob) => {
          this.fileUploadService.triggerDownload(file, `MR-${item.mrNumber}`);
          item.generatingDocument = false;
        },
        (error) => {
          this.messageService.add({ severity: 'error', summary: error });
          item.generatingDocument = false;
        });
  }

  downloadGrnDocument(item: WarehouseItem) {
    if (item.generatingDocument ||
      !item.grnExternalDocumentId) {
      return;
    }
    item.generatingDocument = true;

    this.fileUploadService.getBlobWithFilename(item.grnExternalDocumentId!, 'operationalprocess')
      .pipe(finalize(() => item.generatingDocument = false))
      .subscribe((response: { blob: Blob; filename: string; }) => {
        this.fileUploadService.triggerDownload(response.blob, response.filename || `GRN-${item.mrNumber}`);
      });
  }

  groupDataByMrNumber(data: WarehouseItem[]): IMrnGroup[] | undefined {
    const grouped: { [s: string]: any[]; } = this.helperService.groupBy(data ?? [], 'mrNumber');
    if (!grouped) {
      return;
    }
    return Object.entries(grouped).map(([mrNumber, items]) => {
      return {
        mrNumber: mrNumber,
        warehouseItems: items,
        officeCode: items?.[0]?.officeCode,
        source: items?.[0]?.source,
        grnExternalDocumentId: items?.find(i => i.grnExternalDocumentId)?.grnExternalDocumentId,
        numberOfPackages: data?.map(w => w.noOfPackage ?? 0)
          .reduce((prev, curr) => prev + curr, 0)
      };
    });
  }
}

