import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ReplaySubject, Subject, combineLatest } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CacheService } from 'src/app/core/services/cache.service';
import { DeliveryRequestCartService } from 'src/app/delivery-requests/delivery-request-cart/delivery-request-cart.service';
import { TableCellTemplatesComponent } from 'src/app/shared/components/table-cell-templates/table-cell-templates.component';
import { TableColumnDisplayType } from 'src/app/shared/model/table-column-display-type.enum';
import { TableColumnsConfig } from 'src/app/shared/model/table-columns-config.model';
import { ModeOfTransport } from 'src/app/shared/model/transport-mode.const';
import { OperationalProcessCode } from 'src/app/vessel-call/model/order/operational-process-code.enum';
import { ICargoLine } from '../../../shared/model/order/order.interface';
import { IPackage, IPackageDeliveryState } from "../../../shared/model/order/package.interface";
import { AppianPackageStatus } from '../../model/appian-package-status.enum';
import { PurchaseOrderPackagesColumns } from './purchase-order-packages-columns.const';

@Component({
  selector: 'prt-purchase-order-packages-grid',
  templateUrl: './purchase-order-packages-grid.component.html',
  styleUrls: ['./purchase-order-packages-grid.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class PurchaseOrderPackagesGridComponent implements OnInit, OnDestroy {
  private _destroyed$ = new Subject<void>();
  private data$ = new ReplaySubject<IPackage[]>(1);
  private showDelivery$ = new ReplaySubject<boolean>(1);

  _data?: IPackage[];
  loadingPackageState = true;
  OperationalProcessCode = OperationalProcessCode;
  @Input() cargoDetails?: { poNumber: string, customerCode: string, customerName: string; };
  @Input()
  set data(d: IPackage[]) {
    this.cacheService.getUomLookup().subscribe(uoms => {
      d.forEach(p => {
        let volumeUom = uoms.find(u => u.code === p.volumeUom);
        let weightUom = uoms.find(u => u.code === p.weightUom);
        let dimensionUom = uoms.find(u => u.code === p.dimensionUoMcode);
        p.volumeUomName = volumeUom?.symbol || volumeUom?.name || p.volumeUomName;
        p.weightUomName = weightUom?.symbol || weightUom?.name || p.weightUomName;
        p.dimensionUoMname = dimensionUom?.symbol || dimensionUom?.name || p.dimensionUoMname;
        p.noOfPackages = p.cargoLines.map(x => x.receivedQuantity).reduce((prev, curr) => prev + curr, 0);
      });
      this._data = d;
      this.data$.next(d);
    });
  }
  @Input() heading?: string;
  @Input() headingIconUrl?: string;
  @Input() selectable: boolean = false;
  @Input() showHeading: boolean = true;

  _showShipment = false;
  _showLocation = false;
  _showDelivery = false;
  @Input()
  set showShipment(s: boolean) {
    this._showShipment = s;
    this.updateColumns();
  }
  @Input()
  set showLocation(s: boolean) {
    this._showLocation = s;
    this.updateColumns();
  }
  @Input()
  set showDelivery(s: boolean) {
    this._showDelivery = s;
    this.updateColumns();
    this.showDelivery$.next(s);
  }
  @Input() showLines: boolean = false;
  @Output() packageSelected = new EventEmitter<IPackage>();
  @Output() lineItemSelected = new EventEmitter<ICargoLine>();
  modeOfTransport = ModeOfTransport;

  @ViewChild('rigCodeTemplate') rigCodeTemplate?: TemplateRef<any>;
  @ViewChild('vesselNameTemplate') vesselNameTemplate?: TemplateRef<any>;
  @ViewChild(TableCellTemplatesComponent) public tableCellTemplates?: TableCellTemplatesComponent;

  Math = Math;

  columnsConfig: TableColumnsConfig[] = [];
  selectedForDelivery: IPackage[] = [];
  areAllSelected = false;
  anyInCartDeliveryRequest = false;
  eligibleForSelection: IPackage[] = [];
  packagesToAdd: IPackage[] = [];
  packagesToRemove: IPackage[] = [];

  constructor(
    private cacheService: CacheService,
    private deliveryRequestService: DeliveryRequestCartService,
    private toastService: MessageService) {
    combineLatest([this.data$, this.showDelivery$])
      .pipe(takeUntil(this._destroyed$))
      .subscribe(([data, showDelivery]) => {
        if (showDelivery) {
          this.deliveryRequestService.setPackagesQualifiedForDeliveryRequest(data || []);
          this.deliveryRequestService.setPackagesDeliveryStatus(data || [])
            .pipe(takeUntil(this._destroyed$)).subscribe(x => {
              this.afterPackageDeliveryStateUpdate(data || []);
            });
        }
      });
  }

  ngOnInit(): void {
    this.deliveryRequestService.packagesCartUpdate$
      .pipe(takeUntil(this._destroyed$))
      .subscribe(x => { setTimeout(() => { this.onPackagesDeliveryStateUpdate(x); }, 50); });

    setTimeout(() => {
      this.columnsConfig = [
        // { field: PurchaseOrderPackagesColumns.packageNumber, header: 'Package Number', headerClass: 'w-44', class: 'w-40' },
        { field: PurchaseOrderPackagesColumns.packageCode, header: 'Package/GAC Scan Id', headerClass: 'w-40', class: 'w-40' },
        { field: PurchaseOrderPackagesColumns.packageTypeName, header: 'Package Type', headerClass: 'w-32', class: 'w-32' },
        { field: PurchaseOrderPackagesColumns.noOfPackages, header: 'Line Item quantity', headerClass: 'w-32', class: 'w-32' },
        { field: PurchaseOrderPackagesColumns.dimensions, header: 'Dimensions' },
        { field: PurchaseOrderPackagesColumns.packageLocation, header: 'Current Location', hide: true },
        {
          field: PurchaseOrderPackagesColumns.vessel, header: 'Vessel/Rig', type: TableColumnDisplayType.twoRows, class: 'font-medium text-sm-plus', headerClass: 'w-40',
          firstRow: { field: 'rigCode', type: TableColumnDisplayType.template, template: this.rigCodeTemplate },
          secondRow: { field: 'vesselName', type: TableColumnDisplayType.template, template: this.vesselNameTemplate }
        },
        { field: PurchaseOrderPackagesColumns.shipment, header: 'Shipment', headerClass: 'w-60', class: 'w-60', hide: true },
        { field: PurchaseOrderPackagesColumns.delivery, header: 'Delivery', type: TableColumnDisplayType.checkbox, headerClass: 'w-20 text-center', class: 'w-20 text-center', hide: true }];

      this.updateColumns();
    }, 0);
  }

  onPackagesDeliveryStateUpdate(updates: IPackageDeliveryState[]) {
    this.loadingPackageState = true;
    let updatedPackages: IPackage[] = [];
    updates.forEach(update => {
      if (update.poNumber === this.cargoDetails?.poNumber) {
        let packageToUpdateIndex = this._data?.findIndex(x => x.packageCode === update.packageCode);
        if (packageToUpdateIndex !== -1 && this._data && packageToUpdateIndex !== undefined) {
          let packageToUpdate = this._data[packageToUpdateIndex];
          packageToUpdate = { ...packageToUpdate, ...update };
          this._data[packageToUpdateIndex] = packageToUpdate;
          updatedPackages.push(packageToUpdate);
        }
      }
    });
    if (!updatedPackages.length) {
      this.loadingPackageState = false;
      return;
    }
    this._data = [...(this._data || [])];
    this.afterPackageDeliveryStateUpdate(updatedPackages);
  }

  afterPackageDeliveryStateUpdate(packages: IPackage[]) {
    packages.forEach(p => {
      p.isSelectedForDelivery = p.isInCartDeliveryRequest || p.isInSubmittedDeliveryRequest;
    });
    this.eligibleForSelection = this._data?.filter(this.isDeliverySelectionEnabled) || [];
    this.anyInCartDeliveryRequest = !!this.eligibleForSelection?.find(x => x.isInCartDeliveryRequest);
    packages.forEach(p => {
      this.markPristine(p);
    });
    this.onSelectionUpdate();
    this.loadingPackageState = false;
  }

  isDeliverySelectionEnabled = (p: IPackage) =>
    p.isQualifiedForDeliveryRequest &&
    !p.isInSubmittedDeliveryRequest &&
    p.packageStatusFk === AppianPackageStatus.InStock;

  updateColumns() {
    setTimeout(() => {
      this.setColumnVisibility(this._showLocation, PurchaseOrderPackagesColumns.packageLocation);
      this.setColumnVisibility(this._showShipment, PurchaseOrderPackagesColumns.shipment);
      this.setColumnVisibility(this._showDelivery, PurchaseOrderPackagesColumns.delivery);
    }, 0);
  }

  get columnsLength() {
    return this.columnsConfig.filter(x => !x.hide)?.length || 0;
  }

  setColumnVisibility(visible: boolean, column: string) {
    let columnConfig = this.columnsConfig.find(x => x.field === column);
    if (columnConfig) {
      columnConfig.hide = !visible;
    }
  }

  onRowClick(p: IPackage): void {
    if (!this.selectable) {
      return;
    }

    this.packageSelected.emit(p);
  }

  onPackageDeliveryToggle(p: IPackage): void {
    p.isSelectedForDelivery = !p.isSelectedForDelivery;
    this.markDirty(p);
    this.onSelectionUpdate();
  };

  markDirty(p: IPackage) {
    const listToRemoveFrom = p.isSelectedForDelivery ? this.packagesToRemove : this.packagesToAdd;
    const listToAddTo = p.isSelectedForDelivery ? this.packagesToAdd : this.packagesToRemove;
    let dirtyPackageIndex = listToRemoveFrom.findIndex(x => x.packageCode === p.packageCode);
    if (dirtyPackageIndex !== -1) {
      listToRemoveFrom.splice(dirtyPackageIndex, 1);
    }
    else {
      listToAddTo.push(p);
    }
  }

  markPristine(p: IPackage) {
    this.packagesToAdd = this.packagesToAdd.filter(x => x.packageCode !== p.packageCode);
    this.packagesToRemove = this.packagesToRemove.filter(x => x.packageCode !== p.packageCode);
  }

  onPackageDeliveryAllToggle() {
    this.eligibleForSelection.forEach(p => {
      let previousState = p.isSelectedForDelivery;
      p.isSelectedForDelivery = !this.areAllSelected;
      if (previousState != p.isSelectedForDelivery) {
        this.markDirty(p);
      }
    });
    this.onSelectionUpdate();
  }

  addToDeliveryCart() {
    this.loadingPackageState = true;
    if (!!this.cargoDetails?.poNumber) {
      this.deliveryRequestService.addOrUpdateCartDeliveryRequest(this.cargoDetails, this.packagesToAdd, this.packagesToRemove)
        .pipe(takeUntil(this._destroyed$))
        .subscribe(x => {
          this.toastService.add({ severity: 'success', summary: 'Delivery cart updated' });
        },
          () => {
            this.toastService.add({ severity: 'error', summary: 'Error updating delivery cart' });
            this.loadingPackageState = false;
          });
    }
  }

  onSelectionUpdate() {
    this.selectedForDelivery = this.eligibleForSelection.filter(x => x.isSelectedForDelivery) || [];
    this.areAllSelected = !!this.eligibleForSelection.length && this.eligibleForSelection.length === this.selectedForDelivery.length;
  }

  ngOnDestroy(): void {
    this._destroyed$.next();
    this._destroyed$.complete();
  }

}
