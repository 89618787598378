import { Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { UserService } from 'src/app/core/services/user.service';
import { ShipmentTrail } from 'src/app/shared-logistics/model/shipment-trail.model';
import { WarehouseItem } from 'src/app/shared-logistics/model/warehouse-item.model';
import { OrderSource } from 'src/app/shared/model/order/order-source.enum';
import { IOrder } from 'src/app/shared/model/order/order.interface';
import { JobDataService } from 'src/app/shared/services/job-data.service';
import { MapperService } from 'src/app/shared/services/mapper.service';
import { OperationalProcessType } from 'src/app/vessel-call/model/order/operational-process-code.enum';
import { IPackage } from "../../shared/model/order/package.interface";
import { CargoDataService } from '../../shared/services/cargo-data.service';
import { PurchaseOrderPackagesAndLinesGrouped } from '../purchase-order-packages-and-lines-grouped.model';
import { LineItemStageEnum } from './../../shared-logistics/model/line-item-stage.enum';
import { IPOShipmentDto } from './../../shared/model/purchase-order-tracking.dto';

@Injectable({
  providedIn: 'root'
})
export class PurchaseOrderService {
  private salesOrder: BehaviorSubject<IOrder | null> = new BehaviorSubject<IOrder | null>(null);
  salesOrder$ = this.salesOrder.asObservable();
  packagesAndLinesGrouped?: PurchaseOrderPackagesAndLinesGrouped;

  // private logs: BehaviorSubject<PrtLog[]> = new BehaviorSubject<PrtLog[]>([]);
  // logs$ = this.logs.asObservable();
  private shipmentTrails: BehaviorSubject<ShipmentTrail[]> = new BehaviorSubject<ShipmentTrail[]>([]);
  shipmentTrails$ = this.shipmentTrails.asObservable();
  private warehouseItems: BehaviorSubject<WarehouseItem[]> = new BehaviorSubject<WarehouseItem[]>([]);
  warehouseItems$ = this.warehouseItems.asObservable();


  private selectedPackage: BehaviorSubject<IPackage | null> = new BehaviorSubject<IPackage | null>(null);
  selectedPackage$ = this.selectedPackage.asObservable();

  private selectedShipmentTrails: BehaviorSubject<ShipmentTrail[]> = new BehaviorSubject<ShipmentTrail[]>([]);
  selectedShipmentTrails$ = this.selectedShipmentTrails.asObservable();
  private selectedWarehouseItems: BehaviorSubject<WarehouseItem[]> = new BehaviorSubject<WarehouseItem[]>([]);
  selectedWarehouseItems$ = this.selectedWarehouseItems.asObservable();

  loadingTrackingDetails = false;

  constructor(
    private cargoDataService: CargoDataService,
    private jobDataService: JobDataService,
    private mapperService: MapperService,
    private userProfileService: UserService
  ) {
    combineLatest([this.selectedPackage$, this.shipmentTrails$, this.warehouseItems$])
      .subscribe(([p, shipments, whItems]) => {
        let selectedShipments: ShipmentTrail[] = [];
        let selectedWhItems: WarehouseItem[] = [];
        if (p) {
          if (shipments?.length) {
            selectedShipments = shipments.filter(s => s.packageIds?.filter(pId => pId === p.packageId || pId === p.parentPackageId));
          }
          if (whItems?.length) {
            selectedWhItems = whItems.filter(wh => wh.packageCode == p.packageCode || wh.packageCode == p.parentPackageCode);
          }
        }
        this.selectedShipmentTrails.next(selectedShipments);
        this.selectedWarehouseItems.next(selectedWhItems);
      });

    combineLatest([this.salesOrder$, this.shipmentTrails])
      .subscribe(([salesOrder, shipmentTrails]) => {
        this.packagesAndLinesGrouped?.groups.filter(olg => olg.stage === LineItemStageEnum.InTransit).forEach(g => {
          g.packages?.forEach(p => {
            let trails = shipmentTrails.filter(s => s.isActive && s.packageIds &&
              (s.packageIds.indexOf(p.packageId) >= 0 ||
                (p.parentPackageId && s.packageIds.indexOf(p.parentPackageId) >= 0)));
            if (trails?.[0]) {
              //already sorted by opId but should be only 1 since only one should be active
              p.shipmentInfo = trails[trails.length - 1];
            }
          });
        });
      });
  }

  loadSalesOrder(groupCode: string, poNumber: string): void {
    this.shipmentTrails.next([]);
    this.loadingTrackingDetails = true;
    this.userProfileService.userProfile$.pipe(
      filter(u => u !== null)).subscribe(u => {
        this.cargoDataService.getSalesOrder(groupCode, !!u?.isCustomerGroup, poNumber).pipe(
          tap(po => {
            let packageIds = po?.configuration?.cargo?.packages?.map(p => p.packageId) || [];
            this.jobDataService.getPoShipmentDetails(po.configuration!.cargo!.cargoId, !!u?.isCustomerGroup, packageIds).subscribe(dto => {
              this.loadingTrackingDetails = false;
              let shipmentTrails: ShipmentTrail[] = [];
              let map = new Map();
              dto.shipmentDtos.forEach((dto: IPOShipmentDto) => {
                if (!map.has(dto.jobOperationalProcessId)) {
                  map.set(dto.jobOperationalProcessId, true);
                  shipmentTrails.push(this.mapperService.mapPoShipmentDtoToShipmentTrail(dto));
                }
              });
              shipmentTrails = shipmentTrails.sort((a, b) => a.opId - b.opId);

              this.shipmentTrails.next(shipmentTrails);

              let allWarehouseItems = dto.warehousePackages.flatMap(dto => this.mapperService.mapPoWarehousePackageDtoToWarehouseItems(dto));
              let distinctWarehouseItemGacScanIds = [...new Set(allWarehouseItems.map(x => x.packageCode))];

              this.warehouseItems.next(distinctWarehouseItemGacScanIds.map(id => {
                let items = allWarehouseItems.filter(x => x.packageCode === id);
                let whOutPackage = items.find(x => x.operationalProcessType === OperationalProcessType.WarehouseOut);
                if (whOutPackage) {
                  const grnNumber = items.filter(x => x.grnExternalDocumentId).map(x => x.grnExternalDocumentId);
                  whOutPackage.grnExternalDocumentId = grnNumber[grnNumber.length - 1];
                  return whOutPackage;
                }
                return items[0];
              }));
            });
          })
        ).subscribe(po => {
          if (po.configuration?.cargo?.documents && po.source === OrderSource.Legacy) {
            po.configuration.cargo.documents.forEach(doc => doc.isLegacy = true);
          }

          this.packagesAndLinesGrouped = PurchaseOrderPackagesAndLinesGrouped.BuildFromCargo(po.configuration?.cargo);

          this.salesOrder.next(po);
        });
        //shipment trail mapping from poshipmentdto

      });
  }

  selectPackage(packageItem: IPackage | null): void {
    this.selectedPackage.next(packageItem);
  }

  reset() {
    this.shipmentTrails.next([]);
    this.warehouseItems.next([]);
    this.selectPackage(null);
    this.salesOrder.next(null);
  }
}
