import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IJob } from '../model/order/job.interface';
import { IPurchaseOrderTrackingRequestDto } from '../model/purchase-order-tracking-request.dto';
import { IPurchaseOrderTrackingDto } from '../model/purchase-order-tracking.dto';
import { ShipmentDetailsDto } from '../model/shipment/shipment-details.dto';
const orderServiceUrl = environment.orderServiceApiUrl;

@Injectable({
  providedIn: 'root'
})
export class JobDataService {

  constructor(private httpClient: HttpClient) { }

  getShipmentDetails(opId: number, jobNumber: string, rigCodes: string, isGroupCustomer: boolean): Observable<ShipmentDetailsDto> {
    const headers = new HttpHeaders({
      'Prt-Is-Group-Customer': `${isGroupCustomer}`
    });
    return this.httpClient.get<ShipmentDetailsDto>(`${orderServiceUrl}/jobs/getshipmentdetails?jobNumber=${jobNumber}&opId=${opId}&rigCodes=${rigCodes}`, { headers: headers });
  }

  getJobByJobId(jobNumber: string, jobId: number): Observable<IJob> {
    return this.httpClient.get<IJob>(`${orderServiceUrl}/jobs/getjobbyjobidforportal?jobNumber=${jobNumber}&jobId=${jobId}`);
  }

  getJobByOpId(jobNumber: string, opId: number): Observable<IJob> {
    return this.httpClient.get<IJob>(`${orderServiceUrl}/jobs/getjobbyopidforportal?jobNumber=${jobNumber}&opId=${opId}`);

  }

  getPoShipmentDetails(cargoId: number, isGroupCustomer: boolean, packageIds: number[]): Observable<IPurchaseOrderTrackingDto> {
    const headers = new HttpHeaders({
      'Prt-Is-Group-Customer': `${isGroupCustomer}`
    });
    let purchaseOrderTrackingRequest: IPurchaseOrderTrackingRequestDto = {
      cargoId: cargoId,
      packageIds: packageIds
    };
    return this.httpClient.post<IPurchaseOrderTrackingDto>(`${orderServiceUrl}/jobs/getposhipmentdetails`, purchaseOrderTrackingRequest, { headers: headers });
  }
}
