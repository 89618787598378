export type LineItemStatus = 'Open' | 'InTransit' | 'Arrived' | 'CustomsClearance' | 'InStock' | 'Completed' | 'Delivered' | 'OnHold' | 'Pending' | 'Cancelled' | 'COMMON';
export class LineItemStage {
  constructor(
    public name: string,
    public headingIconUrl: string,
    public type: LineItemStatus
  ) { }
}

export const LineItemStageEnum: Record<LineItemStatus, LineItemStage> = {
  Open: new LineItemStage('OPEN', '/assets/images/crane.svg', 'Open'),
  InTransit: new LineItemStage('IN TRANSIT', '/assets/images/transit.svg', 'InTransit'),
  Arrived: new LineItemStage('ARRIVED', '/assets/images/arrived.svg', 'Arrived'),
  CustomsClearance: new LineItemStage('CUSTOMS CLEARANCE', '/assets/images/transit.svg', 'CustomsClearance'),
  InStock: new LineItemStage('IN STOCK', '/assets/images/stock.svg', 'InStock'),
  Completed: new LineItemStage('COMPLETED', '/assets/images/completed.svg', 'Completed'),
  Delivered: new LineItemStage('DELIVERED', '/assets/images/completed.svg', 'Delivered'),
  OnHold: new LineItemStage('ON HOLD', '/assets/images/onHold.svg', 'OnHold'),
  Pending: new LineItemStage('PENDING', '/assets/images/pending.svg', 'Pending'),
  Cancelled: new LineItemStage('CANCELLED', '', 'Cancelled'),
  COMMON: new LineItemStage('', '', 'COMMON')
};

export const LineItemStageList: Record<number, LineItemStage> = {
  0: LineItemStageEnum.Open,
  1: LineItemStageEnum.InTransit,
  2: LineItemStageEnum.CustomsClearance,
  3: LineItemStageEnum.InStock,
  4: LineItemStageEnum.Completed,
  5: LineItemStageEnum.Delivered
};
