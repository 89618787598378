<p-accordionTab>
  <ng-template pTemplate="header">
    <div class="flex flex-col gap-1 divide-y-2 w-full">
      <div class="flex">
        <div class="flex justify-between w-full">
          <div class="flex flex-wrap justify-between w-5/6 items-center">
            <ng-container *ngFor="
              let chunk of chunkedDeliveryRequestInfo">
              <div class="w-1/3 px-2 my-2"
                   *ngFor="let deliveryInfo of chunk">
                <div class="text-center">
                  <h3 class="text-xs line-clamp-1">{{ deliveryInfo.label }}</h3>
                  <span class="text-xs line-clamp-1">{{ deliveryInfo.data }}</span>
                </div>
              </div>
            </ng-container>
          </div>
          <div class="w-1/6 px-2 my-2 flex items-center justify-start">
            <span class="text-xs line-clamp-1 text-center  w-22">
              <!-- <h3 class="text-xs line-clamp-1 mb-1">Status</h3> -->
              <p-tag [severity]="status?.severity"
                     styleClass="py-2px px-3 font-normal mr-auto"
                     [rounded]="true"
                     value="{{ status?.label }}"></p-tag>
            </span>
          </div>
        </div>
        <div class="flex gap-2 items-center justify-center text-center text-xs flex-auto flex-grow-0 flex-shrink-0 px-2 my-2 ">
          <button pButton
                  [disabled]="!isCancelAllowed"
                  type="button"
                  [label]="isCancelLoading ? '' : 'Cancel Request'"
                  class="p-button-danger p-button-sm text-xs w-28 justify-center"
                  (click)="clickCancel(_deliveryRequest?.id, $event)"
                  [loading]="isCancelLoading"></button>
        </div>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="content">
    <prt-delivery-request-packages-grid [packages]="_deliveryRequest?.packages || []"
                                        [request]="_deliveryRequest || undefined"
                                        [showValidation]="false"
                                        [viewOnly]="true"></prt-delivery-request-packages-grid>

    <div class="flex gap-3 my-3">
      <textarea pInputTextarea
                [value]="_deliveryRequest?.gacInstruction || ''"
                [rows]="3"
                style="resize: none"
                placeholder="Instruction to GAC"
                class="w-full"
                [disabled]="true"></textarea>

      <textarea pInputTextarea
                [value]="_deliveryRequest?.customsInstruction || ''"
                resize
                style="resize: none"
                [rows]="3"
                placeholder="Instruction to Customs"
                class="w-full"
                [disabled]="true"></textarea>
    </div>
  </ng-template>
</p-accordionTab>

<p-confirmPopup></p-confirmPopup>