import { PurchaseOrderSearchScoringProfileTypes } from 'src/app/shared/model/purchase-order-search-scoring-profile-types';
import { PurchaseOrderStageEnum } from '../model/purchase-order-status-enum';

export class PurchaseOrderSearchProfileServiceService {
  applyPoSearchScoringProfile(searchTerm: string): PurchaseOrderSearchScoringProfileTypes {
    const names =
      Object
        .values(PurchaseOrderStageEnum)
        .map(item => item.toLowerCase());
    if (names.includes(searchTerm.toLocaleLowerCase())) {
      return 'po-list-status-boost-profile';
    }
    return undefined;
  }
}
