export enum AppianPackageStatus {
  InProgress = 1,
  Received = 2,
  InStock = 3,
  Open = 4,
  InTransit = 5,
  Delivered = 6,
  Picked = 7,
  Packed = 8,
  Loaded = 9,
  AuthorizedToPick = 10,
  OnHold = 11,
  Arrived = 12,
  Pending = 13,
  Cancelled = 14,
  UnderClearance = 15,
  Onboard = 16,
  SeeRemarks = 17,
  Offboard = 18,
  Forwarded = 19,
  InSupplierWH = 20
}
