import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import * as LZString from 'lz-string';
import { BehaviorSubject, ReplaySubject, Subject, combineLatest, of } from 'rxjs';
import { catchError, filter, finalize, map, tap } from 'rxjs/operators';
import { User } from 'src/app/core/models/user.model';
import { HelperService } from 'src/app/core/services/helper.service';
import { PortalCustomerConfigurationService } from 'src/app/core/services/portal-customer-configuration.service';
import { UserService } from 'src/app/core/services/user.service';
import { LayoutService } from 'src/app/layout/layout.service';
import { PurchaseOrderPackagesAndLinesGroup } from 'src/app/purchase-orders/purchase-order-packages-and-lines-grouped.model';
import { AppianPackageStatus } from 'src/app/shared-logistics/model/appian-package-status.enum';
import { LineItemStageEnum } from 'src/app/shared-logistics/model/line-item-stage.enum';
import { DeliveryInstructionStatuses } from 'src/app/shared/model/delivery-instruction/delivery-instruction-status.enum';
import { CartDto, DeliveryInstruction, DeliveryInstructionDto, DeliveryInstructionPackage } from 'src/app/shared/model/delivery-instruction/delivery-instruction.dto';
import { PackageCodePoNumber } from 'src/app/shared/model/delivery-instruction/package-code-po-number.dto';
import { SendDeliveryInstructionResponse } from 'src/app/shared/model/delivery-instruction/send-delivery-instruction-response.dto';
import { CargoDetails } from 'src/app/shared/model/order/cargo-details.dto';
import { OrderSource } from 'src/app/shared/model/order/order-source.enum';
import { IPackage, IPackageDeliveryState } from 'src/app/shared/model/order/package.interface';
import { DeliveryRequestDataService } from '../delivery-request-data.service';
import { DeliveryRequestCartComponent } from './delivery-request-cart.component';

const nonVesselRelatedVesselCode = 'N/A';
const localStorageCartKey = 'deliveryRequestCartKey';
const localStoragePackageStateKey = 'deliveryRequestPackageStateKey';

@Injectable({
  providedIn: 'root'
})
export class DeliveryRequestCartService {
  private cartSubject$ = new BehaviorSubject<DeliveryInstruction[]>([]);
  cart$ = this.cartSubject$.asObservable();
  private packagesCartUpdateSubject = new ReplaySubject<IPackageDeliveryState[]>(1);
  packagesCartUpdate$ = this.packagesCartUpdateSubject.asObservable();
  private isCartLoadingSubject = new BehaviorSubject<boolean>(false);
  isCartLoading$ = this.isCartLoadingSubject.asObservable();
  private deliveryRequestSuccessSubject = new BehaviorSubject<DeliveryInstruction[]>([]);
  deliveryRequestSuccess$ = this.deliveryRequestSuccessSubject.asObservable();

  serverError: boolean = false;
  currentCartValue: DeliveryInstruction[] = [];
  lastPackagesStateUpdate: IPackageDeliveryState[] = [];
  userProfile: User | null = null;

  constructor(
    private userService: UserService,
    private deliveryRequestDataService: DeliveryRequestDataService,
    private portalCustomerConfigurationService: PortalCustomerConfigurationService,
    private layoutService: LayoutService,
    private router: Router,
    private helperService: HelperService
  ) {
    combineLatest([
      this.userService.userProfile$,
      this.portalCustomerConfigurationService.customerConfiguration$
    ]).subscribe(([user, customerConfiguration]) => {
      this.userProfile = user;
      if (user?.isLogisticsCustomer && customerConfiguration?.canRequestDelivery) {
        this.initCart();
      }
    });

    this.addLocalStorageListeners();
  }

  initService() {
    //do not remove
  }

  initCart() {
    this.layoutService.showDeliveryCartIcon();
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe(() => {
      if (this.layoutService.getCurrentSidebarComponent() === DeliveryRequestCartComponent) {
        this.layoutService.showSidebarComponent(null);
      }
    });
    this.getCart();
  }

  toggleCart() {
    this.layoutService.showSidebarComponent(DeliveryRequestCartComponent);
  }

  getCart() {
    this.isCartLoadingSubject.next(true);
    this.deliveryRequestDataService.getCart()
      .pipe(
        tap(() => this.serverError = false),
        catchError(() => {
          this.serverError = true;
          return of({ value: [], invalidPackages: [] });
        })
      )
      .subscribe(
        ({ value, invalidPackages }) => {
          value.forEach(x => {
            this.setInvalidPackages(x, invalidPackages);
          });
          this.isCartLoadingSubject.next(false);
          this.cartSubject$.next(value);
        }
      );
  }

  setInvalidPackages(cartItem: DeliveryInstruction, invalidPackages: PackageCodePoNumber[]) {
    cartItem.packages.forEach(p => {
      if (invalidPackages.find(x => x.packageCode === p.packageCode && x.poNumber === p.poNumber)) {
        p.isInvalidForCart = true;
      }
    });
  }

  save(request: CartDto) {
    return this.deliveryRequestDataService.addToCart(request).pipe(
      tap(x => {
        this.afterCartSave(x.value, request?.id ?? '', x.invalidPackages);
      })
    );
  }

  send(request: DeliveryInstructionDto) {
    return this.deliveryRequestDataService.sendInstruction(request).pipe(
      tap(x => {
        this.afterSend(x, request.id ?? '');
      })
    );
  }

  showDeliveryRequestSuccess(request: DeliveryInstruction) {
    let currentRequests: DeliveryInstruction[] = HelperService.deepCopy(this.deliveryRequestSuccessSubject.value);
    currentRequests.push(request);
    this.deliveryRequestSuccessSubject.next(currentRequests);
  }

  hideDeliveryRequestSuccess(id: string) {
    let currentRequests: DeliveryInstruction[] = HelperService.deepCopy(this.deliveryRequestSuccessSubject.value);
    let index = currentRequests.findIndex(x => x.id === id);
    if (index !== -1) {
      currentRequests.splice(index, 1);
    }
    this.deliveryRequestSuccessSubject.next(currentRequests);
  }



  addOrUpdateCartDeliveryRequest(cargoDetails: CargoDetails, addedPackages: IPackage[], removedPackages: IPackage[]) {
    this.isCartLoadingSubject.next(true);
    let updatedRequests: CartDto[] = [];
    let loadingRequests: DeliveryInstruction[] = [];
    let existingRequests = this.cartSubject$.value;
    let addPackageToCart = (p: IPackage, toAdd: boolean) => {
      let updatedRequest: CartDto | undefined = this.findRequestInArray(updatedRequests, cargoDetails.customerCode, p.rigCode, p.vesselCode);
      if (!updatedRequest) {
        let existingRequest = this.findRequestInArray(existingRequests, cargoDetails.customerCode, p.rigCode, p.vesselCode);
        if (existingRequest) {
          existingRequest.isLoading = true;
          loadingRequests.push(existingRequest);
          updatedRequest = {
            ...HelperService.deepCopy(existingRequest),
            rigCode: existingRequest.rigCode || p.rigCode,
            vesselName: existingRequest.vesselName || p.vesselName,
            packagesToAdd: [], packagesToRemove: []
          };
        }
      }
      if (!updatedRequest) {
        updatedRequest = {
          customerCode: cargoDetails.customerCode,
          customerName: cargoDetails.customerName,
          rigCode: p.rigCode,
          vesselName: p.vesselName,
          vesselCode: p.vesselCode,
          packagesToAdd: [],
          packagesToRemove: []
        };
      }

      let listToAddTo = toAdd ? updatedRequest.packagesToAdd : updatedRequest.packagesToRemove;
      listToAddTo.push(this.mapCargoPackageToCartPackage(p, cargoDetails));
      this.addIfNotExists(updatedRequests, updatedRequest);
    };

    addedPackages.forEach(p => { addPackageToCart(p, true); });
    removedPackages.forEach(p => { addPackageToCart(p, false); });

    let response;
    if (updatedRequests.length) {
      response = combineLatest(updatedRequests.map(x => this.save(x)));
    }
    else {
      response = of([]);
    }
    return response.pipe(
      finalize(() => {
        loadingRequests.forEach(r => r.isLoading = false);
        this.isCartLoadingSubject.next(false);
      }),
      tap(response => {
        this.packagesCartUpdateSubject.next([...addedPackages, ...removedPackages].map(x => ({ packageCode: x.packageCode, poNumber: x.poNumber, isInCartDeliveryRequest: x.isSelectedForDelivery })));
      }));
  }

  removePackageFromCart(packageCode: string, poNumber: string, id: string) {
    this.isCartLoadingSubject.next(true);
    return this.deliveryRequestDataService.removePackagesFromCart(id, [{ packageCode, poNumber }]).pipe(
      finalize(() => {
        this.isCartLoadingSubject.next(false);
      }),
      tap(response => {
        let cart: DeliveryInstruction[] = HelperService.deepCopy(this.cartSubject$.value);
        let cartIndex = cart.findIndex(x => x.id === id || (!!response?.id && x.id === response.id));

        if (response == null) {
          cart.splice(cartIndex, 1);
        }
        else {
          let cartItem = cart[cartIndex];
          if (cartItem) {
            let packageIndex = cartItem.packages.findIndex(x => x.packageCode === packageCode && x.poNumber === poNumber);
            if (packageIndex !== -1) {
              cartItem.packages.splice(packageIndex, 1);
            }
          }
          cartItem.isLoading = false;
        }
        this.cartSubject$.next(cart);
        this.packagesCartUpdateSubject.next([{ packageCode, poNumber, isInCartDeliveryRequest: false }]);
      })
    );
  }

  isPackageGroupQualifiedForDeliveryRequest(group: PurchaseOrderPackagesAndLinesGroup): boolean {
    if (!group?.packages?.length || group.stage !== LineItemStageEnum.InStock) {
      return false;
    }

    if (group.packages.find(p => this.isPackageQualifiedForDeliveryRequest(p))) {
      return true;
    }

    return false;
  }

  isPackageQualifiedForDeliveryRequest(p: IPackage) {
    if (!p) {
      return false;
    }

    if ((p.packageJobSource === OrderSource.Appian || p.packageJobSource === OrderSource.Legacy)
      && !p.isFleetStock
      && !p.isHold
      && p.packageStatusFk
      && [
        AppianPackageStatus.InStock,
        AppianPackageStatus.Picked,
        AppianPackageStatus.Packed,
        AppianPackageStatus.Loaded,
        AppianPackageStatus.AuthorizedToPick
      ].indexOf(p.packageStatusFk) !== -1
      && (p.rigCode || (p.vesselCode && p.vesselCode !== nonVesselRelatedVesselCode))) {
      return true;
    }

    return false;
  }

  isPackageInCart(p: IPackage) {
    return !!this.cartSubject$.value.find(draft => draft.packages.find(x => x.packageCode === p.packageCode && x.poNumber === p.poNumber));
  }

  setPackagesQualifiedForDeliveryRequest(packages: IPackage[]) {
    packages.forEach(p => {
      p.isQualifiedForDeliveryRequest = this.isPackageQualifiedForDeliveryRequest(p);
    });
  }

  setPackagesDeliveryStatus(packages: IPackage[]) {
    return this.deliveryRequestDataService.getDeliveryInstructionsForPackages(packages.map(x => x.packageCode))
      .pipe(
        map(deliveryInstructions => {
          packages.forEach(p => {
            let request = deliveryInstructions.find(di => di.packages.find(x => x.packageCode === p.packageCode && x.poNumber === p.poNumber));
            p.isInSubmittedDeliveryRequest = !!request && [
              DeliveryInstructionStatuses.Cancelled.type.toString(),
              DeliveryInstructionStatuses.Draft.type.toString()].indexOf(request.status ?? '') === -1;
            p.isInCartDeliveryRequest = !!request && [
              DeliveryInstructionStatuses.Draft.type.toString()].indexOf(request.status ?? '') !== -1;
          });
        })
      );
  }

  private afterCartSave(response: DeliveryInstruction | null, id: string, invalidPackages?: PackageCodePoNumber[]) {
    let cart: DeliveryInstruction[] = HelperService.deepCopy(this.cartSubject$.value);
    let index = cart.findIndex(x => x.id === id || (!!response?.id && x.id === response.id));
    if (response) {
      if (index !== -1) {
        cart[index] = response;
      }
      else {
        cart.push(response);
      }
      if (invalidPackages?.length) {
        this.setInvalidPackages(response, invalidPackages);
      }
    }
    else if (index !== undefined && index !== -1) {
      cart.splice(index, 1);
    }
    this.cartSubject$.next(cart);
  }

  private afterSend(response: SendDeliveryInstructionResponse, id: string) {
    let cart: DeliveryInstruction[] = HelperService.deepCopy(this.cartSubject$.value);
    let cartIndex = cart.findIndex(x => x.id === id);
    if (response.sent?.invalidPackages?.length) {
      this.setInvalidPackages(cart[cartIndex], response.sent?.invalidPackages);
      this.cartSubject$.next(cart);
      return;
    }

    if (response.sent?.value) {
      this.showDeliveryRequestSuccess(response.sent.value);
      this.packagesCartUpdateSubject.next(response.sent.value.packages.map(x => ({
        packageCode: x.packageCode,
        poNumber: x.poNumber,
        isInCartDeliveryRequest: false,
        isInSubmittedDeliveryRequest: true
      })));
    }

    if (response.remaining.value) {
      this.packagesCartUpdateSubject.next(response.remaining.value.packages.map(x => ({
        packageCode: x.packageCode,
        poNumber: x.poNumber,
        isInCartDeliveryRequest: true,
        isInSubmittedDeliveryRequest: false
      })));
      cart[cartIndex] = response.remaining.value;
      this.setInvalidPackages(cart[cartIndex], response.remaining?.invalidPackages);
    }
    else {
      cart.splice(cartIndex, 1);
    }

    this.cartSubject$.next(cart);
  }

  private addIfNotExists<T extends DeliveryInstruction | CartDto>(requests: T[], request: T) {
    let existingRequest = this.findRequestInArray(requests, request.customerCode, request.rigCode, request.vesselCode);
    if (!existingRequest) {
      requests.push(request);
    }
  }

  private findRequestInArray<T extends DeliveryInstruction | CartDto>(requests: T[], customerCode: string, rigCode: string, vesselCode: string): T | undefined {
    return requests.find(x =>
      x.customerCode === customerCode && (
        (rigCode && (x.rigCode === rigCode || x.vesselCode === rigCode)) ||
        (vesselCode && (x.vesselCode === vesselCode || x.rigCode === vesselCode)))
    );
  }

  private mapCargoPackageToCartPackage(p: IPackage, cargoDetails: CargoDetails): DeliveryInstructionPackage {
    return {
      poNumber: cargoDetails.poNumber,
      packageCode: p.packageCode,
      lineItemQuantity: p.noOfPackages ?? 0,
      packageType: p.packageTypeName || '',
      weight: p.weight ? { value: p.weight, unit: p.weightUom ?? '' } : undefined,
      volume: p.volume ? { value: p.volume, unit: p.volumeUom ?? '' } : undefined,
      length: p.length ? { value: p.length, unit: p.dimensionUoMcode ?? '' } : undefined,
      width: p.width ? { value: p.width, unit: p.dimensionUoMcode ?? '' } : undefined,
      height: p.height ? { value: p.height, unit: p.dimensionUoMcode ?? '' } : undefined,
      packageJobSource: p.packageJobSource,
      isSelected: true
    };
  }

  addLocalStorageListeners() {
    this.addStorageEventListener();
    this.subscribeToCartUpdates();
    this.subscribeToPackageUpdates();
  }

  private addStorageEventListener() {
    if (window?.addEventListener) {
      window.addEventListener("storage", (event) => {
        if (event.storageArea === localStorage) {
          this.handleStorageEvent(event);
        }
      }, false);
    }
  }

  private handleStorageEvent(event: StorageEvent) {
    switch (event?.key) {
      case localStorageCartKey:
        this.updateSubjectFromStorageIfUpdated(event, this.cartSubject$, this.currentCartValue);
        break;
      case localStoragePackageStateKey:
        this.updateSubjectFromStorageIfUpdated(event, this.packagesCartUpdateSubject, this.lastPackagesStateUpdate);
        break;
    }
  }

  updateSubjectFromStorageIfUpdated<T>(event: StorageEvent, subject: Subject<T>, currentState: T) {
    let item = this.getAndValidateItem(event);
    if (item && !this.helperService.isDeepEqual(item.value, currentState)) {
      subject.next(item.value);
    }
  }

  private getAndValidateItem(event: StorageEvent): any {
    try {
      let localStorageItem = JSON.parse(LZString.decompressFromUTF16(event.newValue ?? '') ?? '');
      return localStorageItem.customerCodes === this.getUserCustomerCodes() && localStorageItem;
    }
    catch (e) {
      console.warn(e);
      return false;
    }
  }

  private subscribeToCartUpdates() {
    this.cart$.subscribe(requests => {
      if (!this.helperService.isDeepEqual(requests, this.currentCartValue)) {
        this.currentCartValue = requests;
        const localStorageValue = this.getCartFromLocalStorage();
        if (!this.helperService.isDeepEqual(requests, localStorageValue?.value)) {
          this.writeCartToLocalStorage(requests);
        }
      }
    });
  }

  private subscribeToPackageUpdates() {
    this.packagesCartUpdate$.subscribe(packages => {
      this.lastPackagesStateUpdate = packages;
      const localStorageValue = this.getPackageStateFromLocalStorage();
      if (!this.helperService.isDeepEqual(packages, localStorageValue?.value)) {
        this.writePackageStateToLocalStorage(packages);
      }
    });
  }

  getUserCustomerCodes() {
    return [...this.userProfile?.xrefCompanyCodes ?? [], this.userProfile?.xrefGroupCompanyCodes ?? ''].join(',');
  }

  writePackageStateToLocalStorage(state: IPackageDeliveryState[]) {
    if (localStorage) {
      try {
        localStorage.setItem(localStoragePackageStateKey, LZString.compressToUTF16(JSON.stringify({ value: state, customerCodes: this.getUserCustomerCodes() })));
      }
      catch (e) {
        console.warn(e);
      }
    }
  }

  getPackageStateFromLocalStorage() {
    if (localStorage) {
      const localStorageCompressed = localStorage.getItem(localStoragePackageStateKey);
      if (localStorageCompressed) {
        try {
          let localStorageValue = JSON.parse(LZString.decompressFromUTF16(localStorageCompressed) ?? '');
          return localStorageValue;
        }
        catch (e) {
          localStorage.removeItem(localStoragePackageStateKey);
          console.warn(e);
        }
      }
    }
    return null;
  }


  writeCartToLocalStorage(value: DeliveryInstruction[]) {
    if (localStorage) {
      try {
        localStorage.setItem(localStorageCartKey, LZString.compressToUTF16(JSON.stringify({ value: value, customerCodes: this.getUserCustomerCodes() })));
      }
      catch (e) {
        console.warn(e);
      }
    }
  }

  getCartFromLocalStorage() {
    if (localStorage) {
      const localStorageCompressed = localStorage.getItem(localStorageCartKey);
      if (localStorageCompressed) {
        try {
          let localStorageValue = JSON.parse(LZString.decompressFromUTF16(localStorageCompressed) ?? '');
          return localStorageValue;
        }
        catch (e) {
          localStorage.removeItem(localStorageCartKey);
          console.warn(e);
        }
      }
    }
    return null;
  }

}