<p-table [columns]="columnsConfig"
         [value]="_packages"
         class="gray-header-table da-table delivery-request-packages-grid"
         [showLoader]="false"
         [loading]="isLoading"
         [autoLayout]="true"
         [dataKey]="'packageCodePoNumber'">
  <ng-template pTemplate="header"
               let-columns>
    <tr>
      <th *ngFor="let col of columnsConfig"
          scope="col"
          class="{{ col.headerClass }}">
        <ng-container [ngSwitch]="col.field">
          <ng-container *ngIf="!viewOnly">
            <ng-container *ngSwitchCase="'checkbox'">
              <div class="h-full flex items-start">
                <div class="h-8 flex items-center">
                  <p-checkbox [binary]="true"
                              [ngModel]="areAllSelected"
                              (onChange)="toggleAll()"
                              tooltipStyleClass="ml-2"></p-checkbox>
                </div>
              </div>
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchDefault>
            {{ col.header }}
          </ng-container>
        </ng-container>
      </th>
    </tr>
  </ng-template>
  <ng-template pTemplate="emptymessage">
    <td>
      <div class="ml-4 my-3">No items</div>
    </td>
  </ng-template>
  <ng-template pTemplate="body"
               let-package
               let-columns="columns">
    <tr class="table-row"
        [class.text-primary-500]="showValidation && package.isInvalidForCart">
      <td *ngFor="let col of columns">
        <ng-container [ngSwitch]="col.field">
          <ng-container *ngIf="!viewOnly">
            <ng-container *ngSwitchCase="'checkbox'">
              <p-checkbox [disabled]="showValidation && package.isInvalidForCart"
                          [binary]="true"
                          [ngModel]="package.isSelected || false"
                          (onChange)="togglePackage(package)"></p-checkbox>
            </ng-container>
          </ng-container>

          <ng-container *ngSwitchCase="'poNumber'">
            <div class="flex items-center">
              <i class="pi pi-exclamation-circle text-red-600 mr-1"
                 *ngIf="showValidation && package.isInvalidForCart"
                 pTooltip="This package is not eligible for delivery request any more. Please remove it from cart before proceeding."
                 tooltipPosition="top"
                 tooltipStyleClass="-mt-2"></i>
              <div>{{ package.poNumber }}</div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'weight'">
            {{ package.weight?.value }} {{ package.weight?.unit }}
          </ng-container>
          <ng-container *ngSwitchCase="'dimension'">
            <div class="flex flex-wrap">
              <div *ngIf="package.length"
                   class="mr-2">
                <span class="uppercase text-xs text-primary-300 inline-block">L</span>
                {{ package.length?.value }} {{ package.length?.unit }}
              </div>
              <div *ngIf="package.width"
                   class="mr-2">
                <span class="uppercase text-xs text-primary-300 inline-block">W</span>
                {{ package.width?.value }} {{ package.width?.unit }}
              </div>
              <div *ngIf="package.height"
                   class="mr-2">
                <span class="uppercase text-xs text-primary-300 inline-block">H</span>
                {{ package.height?.value }} {{ package.height?.unit }}
              </div>
            </div>
          </ng-container>
          <ng-container *ngSwitchCase="'volume'">
            {{ package.volume?.value }} {{ package.volume?.unit }}
          </ng-container>
          <ng-container *ngSwitchCase="'createdBy'">
            <div [pTooltip]="package.createdBy"
                 class="w-6 h-6 rounded-full flex justify-center items-center text-primary bg-gray-400 text-2xs font-semibold">
              {{ package.createdBy | initials }}
            </div>
          </ng-container>
          <ng-container *ngIf="!viewOnly">
            <ng-container *ngSwitchCase="'remove'">
              <button (click)="removePackage(package, $event)"
                      pTooltip="Remove"
                      tooltipPosition="top"
                      tooltipStyleClass="-mt-2">
                <i class="pi pi-minus-circle text-red-600 px-2"></i>
              </button>
              <p-confirmPopup [key]="'removePackage-' + package.packageCodePoNumber"
                              [styleClass]="'whitespace-pre-line custom-confirm'"></p-confirmPopup>
            </ng-container>
          </ng-container>
          <ng-container *ngSwitchDefault>
            <prt-table-cell [model]="package"
                            [columnConfig]="col"></prt-table-cell>
          </ng-container>
        </ng-container>
      </td>
    </tr>
  </ng-template>
  <ng-template pTemplate="loadingbody">
    <prt-table-loading-body #loading
                            [columns]="columnsConfig.length - 1"
                            *ngIf="columnsConfig"
                            [rows]="2"></prt-table-loading-body>
  </ng-template>
</p-table>