import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { CacheService } from 'src/app/core/services/cache.service';
import { DeliveryInstruction, DeliveryInstructionPackage } from 'src/app/shared/model/delivery-instruction/delivery-instruction.dto';
import { TableColumnDisplayType } from 'src/app/shared/model/table-column-display-type.enum';
import { TableColumnsConfig } from 'src/app/shared/model/table-columns-config.model';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'prt-delivery-request-packages-grid',
  templateUrl: './delivery-request-packages-grid.component.html',
  styleUrls: ['./delivery-request-packages-grid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  providers: [ConfirmationService],
})
export class DeliveryRequestPackagesGridComponent {
  _packages: DeliveryInstructionPackage[] = [];
  eligiblePackages: DeliveryInstructionPackage[] = [];
  @Output() remove = new EventEmitter<any>();
  @Output() selectionUpdate = new EventEmitter<any>();
  @Input() viewOnly: boolean = false;
  @Input() showValidation: boolean = false;
  @Input() request?: DeliveryInstruction;
  @Input()
  set packages(d: DeliveryInstructionPackage[]) {
    this.isLoading = true;
    this.cacheService.getUomLookup().subscribe((uoms) => {
      d.forEach((p) => {
        let volumeUom = uoms.find((u) => u.code === p.volume?.unit);
        let weightUom = uoms.find((u) => u.code === p.weight?.unit);
        let lengthUom = uoms.find((u) => u.code === p.length?.unit);
        let widthUom = uoms.find((u) => u.code === p.width?.unit);
        let heightUom = uoms.find((u) => u.code === p.height?.unit);
        if (p.volume != null)
          p.volume.unit = volumeUom?.symbol || volumeUom?.name || p.volume.unit;
        if (p.weight != null)
          p.weight.unit = weightUom?.symbol || weightUom?.name || p.weight.unit;
        if (p.length != null)
          p.length.unit = lengthUom?.symbol || lengthUom?.name || p.length.unit;
        if (p.width != null)
          p.width.unit = widthUom?.symbol || widthUom?.name || p.width.unit;
        if (p.height != null)
          p.height.unit = heightUom?.symbol || heightUom?.name || p.height.unit;
      });
      d?.forEach((p) => {
        p.packageCodePoNumber = `${p.packageCode}-${p.poNumber}`;
      });
      this._packages = d;
      d.forEach((p) => p.isInvalidForCart && (p.isSelected = false));
      this.eligiblePackages = d.filter((x) => !x.isInvalidForCart);
      this.onSelectionUpdate();
      this.isLoading = false;
      this.changeDetector.detectChanges();
    });
  }

  columnsConfig: TableColumnsConfig[] = [
    { field: 'checkbox', header: 'checkbox' },
    { field: 'poNumber', header: 'PO', headerClass: 'min-w-28' },
    { field: 'packageCode', header: 'PACKAGE/GAC SCAN ID' },
    { field: 'packageType', header: 'PACKAGE TYPE' },
    { field: 'weight', header: 'WEIGHT' },
    { field: 'dimension', header: 'DIMENSIONS' },
    { field: 'volume', header: 'VOLUME' },
    { field: 'lineItemQuantity', header: 'LINE ITEM QUANTITY' },
    { field: 'createdBy', header: 'ADDED BY', headerClass: 'w-[5ch]' },
    {
      field: 'createdOn',
      header: 'ADDED AT',
      type: TableColumnDisplayType.twoRows,
      headerClass: 'w-[12ch]',
      firstRow: { field: 'createdOn', type: TableColumnDisplayType.mediumDate },
      secondRow: { field: 'createdOn', type: TableColumnDisplayType.shortTime },
    },
    { field: 'remove', header: '' },
  ];

  isLoading = true;
  areAllSelected: boolean = false;
  selectedForDelivery: DeliveryInstructionPackage[] = [];

  constructor(
    private cacheService: CacheService,
    private changeDetector: ChangeDetectorRef,
    private confirmationService: ConfirmationService
  ) { }

  toggleAll() {
    this.eligiblePackages.forEach((p) => {
      p.isSelected = !this.areAllSelected;
    });
    this.onSelectionUpdate();
  }

  togglePackage(p: DeliveryInstructionPackage) {
    p.isSelected = !p.isSelected;
    this.onSelectionUpdate();
  }

  onSelectionUpdate() {
    this.selectionUpdate.next(undefined);
    this.selectedForDelivery =
      this.eligiblePackages.filter((x) => x.isSelected) || [];
    this.areAllSelected =
      !!this.eligiblePackages.length &&
      this.eligiblePackages.length === this.selectedForDelivery.length;
  }

  removePackage(p: DeliveryInstructionPackage, event: MouseEvent) {
    let confirmed = () => {
      this.remove.emit(p);
    };

    if (this._packages.length === 1) {
      this.confirmationService.confirm({
        target: event.target as HTMLElement,
        key: `removePackage-${p.packageCodePoNumber}`,
        icon: 'pi pi-question-circle text-primary-400',
        message:
          'This is the last package. Delivery Request will be removed entirely. \r\nDo you want to proceed?',
        accept: () => confirmed(),
      });
    } else {
      confirmed();
    }
  }
}
